import React from "react";
import { Outlet } from "react-router-dom";

const Users: React.FC = () => {
  return (
    <>
      <div>
        <h2 className="lg:text-lg font-bold">المستخدمين</h2>
      </div>
      <div className=" flex-1 flex flex-col gap-y-2 overflow-auto">
        <Outlet />
      </div>
    </>
  );
};

export default Users;

import React from "react";
import { useNavigate } from "react-router-dom";

const NotFounded: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div
      className="h-screen w-screen flex flex-col gap-y-3 justify-center items-center"
      dir="ltr"
    >
      <h1 className="text-sm md:text-2xl lg:text-3xl font-bold">
        404 | Not founded page
      </h1>
      <button
        className=" bg-blue hover:opacity-70 transition-all duration-100 text-lg py-2 px-6 rounded-md text-white cursor-pointer"
        onClick={(): void => navigate(-1)}
      >
        رجوع
      </button>
    </div>
  );
};

export default NotFounded;

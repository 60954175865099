import React from "react";
import Pagination from "./Pagination";

// => props component
interface prop {
  tHead: any[];
  children: any;
  total: number;
  curent_page: number;
  number_page: number;
  nextPage: any;
  prevPage: any;
  loading: boolean;
}

const Table: React.FC<prop> = ({
  tHead,
  children,
  total,
  curent_page,
  number_page,
  nextPage,
  prevPage,
  loading,
}) => {
  const Head = tHead.map((item,index: number) => (
    <th className="p-2 text-lg font-bold text-center whitespace-nowrap" key={index}>
      <p>{item}</p>
    </th>
  ));

  return (
    <>
      <div className=" h-vh-minus-140 overflow-scroll">
        <table className="min-w-[400px] w-full">
          <thead className=" bg-ddd border-ddd border-r-2 border-l-2">
            <tr>{Head}</tr>
          </thead>
          <tbody>
              {children}
          </tbody>
        </table>
      </div>
      <Pagination
        total={total}
        currentPage={curent_page}
        numPage={number_page}
        nextFun={nextPage}
        prevFun={prevPage}
      />
    </>
  );
};

export default Table;

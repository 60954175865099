export const Roles = [
  "user",
  "users-list",
  "delete-user",
  "update-user",
  "add-user",
  "client",
  "client-filter",
  "clients-list",
  "update-client",
  "delete-client",
  "add-client",
  "permission",
  "permission-list",
  "update-permission",
  "delete-permission",
  "add-permission",
  "accountant-nationality",
  "accountant-nationality-list",
  "add-accountant-nationality",
  "update-accountant-nationality",
  "delete-accountant-nationality",
  "client-type",
  "client-type-list",
  "delete-client-type",
  "add-client-type",
  "update-client-type",
  "search-by-name",
  "search-by-name-mob",
  "client-areas",
  "area",
  "area-list",
  "update-area",
  "delete-area",
  "add-area",
  "orders",
  "order-list",
  "add-order",
  "update-order",
  "delete-order",
  "order-driver",
  "get-all-orders-today",
  "filter-by-status",
  "search-by-order-number"
];

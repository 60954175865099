import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// initialState
type initState = {
  formPermission: {
    id?: number;
    name: string;
    permission: string[];
  };
  editForm: boolean;
  isError: boolean;
};
const initialState: initState = {
  formPermission: {
    name: "",
    permission: [],
  },
  editForm: false,
  isError: false,
};

// slice for Permission
const formSlicePermission = createSlice({
  name: "formPermission",
  initialState,
  reducers: {
    // add data permission
    setName: (state, action: PayloadAction<string>): void => {
      state.formPermission.name = action.payload;
    },
    setRoles: (
      state,
      action: PayloadAction<{ check: boolean; val: string }>
    ): void => {
      action.payload.check
        ? state.formPermission.permission.push(action.payload.val)
        : (state.formPermission.permission =
            state.formPermission.permission.filter(
              (role) => role !== action.payload.val
            ));
    },

    // edit data permission
    editName: (state, action: PayloadAction<string>): void => {
      state.formPermission.name = action.payload;
    },
    editRoles: (
      state,
      action: PayloadAction<{ check: boolean; val: string }>
    ): void => {
      action.payload.check
        ? state.formPermission.permission.push(action.payload.val)
        : (state.formPermission.permission =
            state.formPermission.permission.filter(
              (role) => role !== action.payload.val
            ));
    },

    // btn setDefault Data
    setDefault: (state, action) => {
      state.isError = false;
      if (action.payload === undefined) {
        state.editForm = false;
        state.formPermission.name = "";
        state.formPermission.permission = [];
      } else {
        state.editForm = true;
        state.formPermission.name = "";
        state.formPermission.permission = [];
        state.formPermission.id = action.payload.id;
        state.formPermission.name = action.payload.name;
        action.payload.permissions.map((item: any) => state.formPermission.permission.push(item.name))
      }
    },

    // set error
    setIsError: (state) => {
      state.isError = true;
    }
  },
});

export default formSlicePermission.reducer;
export const { setName, setRoles, editName, editRoles, setDefault, setIsError } =
  formSlicePermission.actions;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { URL_BASEURL } from "../../api/api";
import axios from "axios";
import Cookie from "cookie-universal";
const cookie = Cookie();

/*
  ==============================
  ====== CreateAsyncThunk ======
  ==============================
*/

// [1] => Clients.
export const getTotalClient = createAsyncThunk(
  "dashboard/totalClient",
  async (_, thunkApi) => {
    try {
      const res = await axios
        .get(`${URL_BASEURL}api/getClientCount`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);
      const data = await res.data;
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

// [2] => Users.
export const getTotalUsers = createAsyncThunk(
  "dashboard/totalUsers",
  async (_, thunkApi) => {
    try {
      const res = await axios
        .get(`${URL_BASEURL}api/getUserCount`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);
      const data = await res.data;
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

// [3] => Orders.
export const getTotalOrders = createAsyncThunk(
  "dashboard/totalOrders",
  async (_, thunkApi) => {
    try {
      const res = await axios
        .get(`${URL_BASEURL}api/getOrderCount`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);
      const data = await res.data;
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/*
  ===================
  ====== Slice ======
  ===================
*/

// Default State
type initState = {
  totalClients: number;
  totalUsers: number;
  totalOrders: number;
};
const initialState: initState = {
  totalClients: 0,
  totalUsers: 0,
  totalOrders: 0,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // [1] => Clients.
    builder.addCase(getTotalClient.fulfilled, (state, action) => {
      state.totalClients = action.payload.data;
    });

    // [2] => Users.
    builder.addCase(getTotalUsers.fulfilled, (state, action) => {
      state.totalUsers = action.payload.data;
    });

    // [3] => Orders.
    builder.addCase(getTotalOrders.fulfilled, (state, action) => {
      state.totalOrders = action.payload.data;
    });
  },
});

export default dashboardSlice.reducer;

import { configureStore } from "@reduxjs/toolkit";
// ---
import authSlice from "./auth/authSlice";
import usersSlice from "./users/main/usersSlice";
import permissionSlice from "./users/permission/permissionSlice";
import clientSlice from "./clients/main/clientSlice";
import areaSlice from "./clients/area/areaSlice";
import workSlice from "./clients/work/workSlice";
import nationalitySlice from "./clients/nationality/nationalitySlice";
import orderSlice from "./order/invoiceSlice";
import dashboardSlice from "./admin/dashboardSlice";
import mainSlice from "./main/mainSlice";

// ---
import formSliceUser from "./users/main/formSliceUser";
import formSlicePermission from "./users/permission/formSlicePermission";
import formSliceClient from "./clients/main/formSliceClient";
import formSliceAuth from "./auth/formSliceAuth";
import formSliceArea from "./clients/area/formSliceArea";
import fromSliceNationality from "./clients/nationality/fromSliceNationality";
import fromSliceWork from "./clients/work/formSliceWork";
import formSliceOrder from "./order/formSliceOrder";

const store = configureStore({
  reducer: {
    auth: authSlice,
    users: usersSlice,
    permission: permissionSlice,
    mainSlice: mainSlice,
    clients: clientSlice,
    areas: areaSlice,
    nationality: nationalitySlice,
    work: workSlice,
    order: orderSlice,
    dashboard: dashboardSlice,

    // forms
    formAuth: formSliceAuth,
    formUsers: formSliceUser,
    formClient: formSliceClient,
    formpermission: formSlicePermission,
    formArea: formSliceArea,
    fromNationality: fromSliceNationality,
    fromWork: fromSliceWork,
    formOrder: formSliceOrder,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import { createSlice } from "@reduxjs/toolkit";

/*
  ===================
  ====== Slice ======
  ===================
*/

type initState = {
  formArea: { area_name: string };
  isError: boolean;
};

const initialState: initState = {
  formArea: {
    area_name: "",
  },
  isError: false,
};

const formSliceArea = createSlice({
  name: "formArea",
  initialState: initialState,
  reducers: {
    // Set Data.
    setName: (state, action) => {
      state.formArea.area_name = action.payload;
    },

    // Set Error.
    setisError: (state) => {
      state.isError = true;
    },

    // Set Default.
    setDefault: (state, action) => {
      if (action.payload === undefined) {
        state.formArea.area_name = "";
        state.isError = false;
      }
    },
  },
});

export default formSliceArea.reducer;
export const { setName, setisError, setDefault } = formSliceArea.actions;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { End_AREAS, URL_BASEURL } from "../../../api/api";
import { areaState } from "./interfaceAreaSlice";
import axios from "axios";
// => Notification
import { useMsgSuccess } from "../../../hooks/toastify/notification";
// => Cookie
import Cookie from "cookie-universal";
const cookie = Cookie();

/*
  ==============================
  ====== CreateAsyncThunk ======
  ==============================
*/

// [1] => Get All Areas.
export const getAllArea = createAsyncThunk(
  "area/getAll",
  async (_, thunkAPI) => {
    try {
      const res = await axios
        .get(`${URL_BASEURL}${End_AREAS}`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [2] => Create Area.
export const createArea = createAsyncThunk(
  "area/create",
  async (areaData: { area_name: string }, thunkAPI) => {
    try {
      const res = await axios
        .post(`${URL_BASEURL}${End_AREAS}`, areaData, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [3] => Delete Area.
export const deleteArea = createAsyncThunk(
  "area/delete",
  async (areaId: number, thunkAPI) => {
    try {
      await axios.delete(`${URL_BASEURL}${End_AREAS}/${areaId}`, {
        headers: {
          Authorization: "bearer " + cookie.get("access_token"),
        },
      });

      return areaId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [4] => Filter Area.
export const filterClientByArea = createAsyncThunk(
  "area/filterArea",
  async (idtypework: number, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios
        .get(`${URL_BASEURL}api/filters/${idtypework}`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/*
  ===================
  ====== Slice ======
  ===================
*/

const initialState: areaState = {
  areas: [],
  isLoading: false,
};

const areaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // [1] => Get All Areas.
    builder.addCase(getAllArea.pending, (state, action) => {});
    builder.addCase(getAllArea.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.areas = action.payload.response.data;
      }
    });
    builder.addCase(getAllArea.rejected, (state, action) => {});

    // [2] => Create Area.
    builder.addCase(createArea.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createArea.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status) {
        state.areas.push(action.payload.response);
        useMsgSuccess(action.payload.message);
      }
    });
    builder.addCase(createArea.rejected, (state, action) => {
      state.isLoading = false;
    });

    // [3] => Delete Area.
    builder.addCase(deleteArea.pending, (state, action) => {});
    builder.addCase(deleteArea.fulfilled, (state, action) => {
      state.areas = state.areas.filter((item) => item.id !== action.payload);
      useMsgSuccess("تم الحذف المنطقة بنجاح");
    });
    builder.addCase(deleteArea.rejected, (state, action) => {});
  },
});

export default areaSlice.reducer;

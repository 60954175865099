import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { End_FILTER, End_WORKS, URL_BASEURL } from "../../../api/api";
import { workState } from "./interfaceWorkSlice";
import axios from "axios";
// => Notification
import { useMsgSuccess } from "../../../hooks/toastify/notification";
// => Cookie
import Cookie from "cookie-universal";
const cookie = Cookie();

/*
  ==============================
  ====== CreateAsyncThunk ======
  ==============================
*/

// [1] => Get All Works.
export const getAllWork = createAsyncThunk(
  "work/getAll",
  async (_, thunkAPI) => {
    try {
      const res = await axios
        .get(`${URL_BASEURL}${End_WORKS}`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [2] => Create Work.
export const createWork = createAsyncThunk(
  "work/craete",
  async (workData: { client_type: string }, thunkAPI) => {
    try {
      const res = await axios
        .post(`${URL_BASEURL}${End_WORKS}`, workData, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [3] => Delete Work.
export const deleteWork = createAsyncThunk(
  "work/delete",
  async (workId: number, thunkAPI) => {
    try {
      await axios.delete(`${URL_BASEURL}${End_WORKS}/${workId}`, {
        headers: {
          Authorization: "bearer " + cookie.get("access_token"),
        },
      });

      return workId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [4] => Filter Work.
export const filterClientByWork = createAsyncThunk(
  "work/filter",
  async (idtypework: number, thunkAPI) => {
    try {
      const res = await axios
        .get(`${URL_BASEURL}${End_FILTER}/${idtypework}`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);
      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/*
  ===================
  ====== Slice ======
  ===================
*/

const initialState: workState = {
  works: [],
  isLoading: false,
};

const workSlice = createSlice({
  name: "work",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // [1] => Get All Works.
    builder.addCase(getAllWork.pending, (state, action) => {});
    builder.addCase(getAllWork.fulfilled, (state, action) => {
      if (action.payload.successful) {
        state.works = action.payload.data.data;
      }
    });
    builder.addCase(getAllWork.rejected, (state, action) => {});

    // [2] => Create Work.
    builder.addCase(createWork.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createWork.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.successful) {
        state.works.push(action.payload.data);
        useMsgSuccess(action.payload.message);
      }
    });
    builder.addCase(createWork.rejected, (state, action) => {
      state.isLoading = false;
    });

    // [3] => Delete Work.
    builder.addCase(deleteWork.pending, (state, action) => {});
    builder.addCase(deleteWork.fulfilled, (state, action) => {
      state.works = state.works.filter((item) => item.id !== action.payload);
      useMsgSuccess("تم الحذف النشاط بنجاح");
    });
    builder.addCase(deleteWork.rejected, (state, action) => {});
  },
});

export default workSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { End_ROLES, URL_BASEURL } from "../../../api/api";
import { permissionState } from "./interfacePermission";
import axios from "axios";
// => Notification
import { useMsgSuccess } from "../../../hooks/toastify/notification";
// => Cookie
import Cookie from "cookie-universal";
const cookie = Cookie();

/*
  ==============================
  ====== CreateAsyncThunk ======
  ==============================
*/

// [1] => Get All permissions.
export const getAllBermission = createAsyncThunk(
  "permission/getAll",
  async (numPage: number, thunkAPI) => {
    try {
      const res = await axios
        .get(`${URL_BASEURL}${End_ROLES}?page=${numPage}`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [2] => Create permission.
export const createBermission = createAsyncThunk(
  "permission/create",
  async (permissionData: any, thunkAPI) => {
    try {
      const res = await axios
        .post(`${URL_BASEURL}${End_ROLES}`, permissionData, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [3] => Update permission.
export const updateBermission = createAsyncThunk(
  "permission/update",
  async (permissionData: any, thunkAPI) => {
    try {
      const res = await axios
        .put(
          `${URL_BASEURL}${End_ROLES}/${permissionData.id}`,
          permissionData,
          {
            headers: {
              Authorization: "bearer " + cookie.get("access_token"),
            },
          }
        )
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [4] => Delete permission.
export const deleteBermission = createAsyncThunk(
  "permission/delete",
  async (permissionId: any, thunkAPI) => {
    try {
      await axios.delete(`${URL_BASEURL}${End_ROLES}/${permissionId}`, {
        headers: {
          Authorization: "bearer " + cookie.get("access_token"),
        },
      });

      return permissionId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/*
  ===================
  ====== Slice ======
  ===================
*/

const initialState: permissionState = {
  isLoading: false,
  permissions: [],
  total: 0,
  curent_page: 1,
  number_page: 0,
};

const permissionSlice = createSlice({
  name: "permission",
  initialState: initialState,
  reducers: {
    // Set Data
    nextPage: (state): void => {
      if (state.curent_page < state.number_page) {
        state.curent_page = state.curent_page + 1;
      }
    },
    prevPage: (state): void => {
      if (state.curent_page > 1) {
        state.curent_page = state.curent_page - 1;
      }
    },
  },
  extraReducers: (builder) => {
    // [1] => Get All permissions.
    builder.addCase(getAllBermission.pending, (state, action): void => {});
    builder.addCase(getAllBermission.fulfilled, (state, action): void => {
      state.permissions = action.payload.data;
      state.total = action.payload.total;
      state.curent_page = action.payload.current_page;
      state.number_page = action.payload.last_page;
    });
    builder.addCase(getAllBermission.rejected, (state, action): void => {});

    // [2] => Create permission.
    builder.addCase(createBermission.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(createBermission.fulfilled, (state, action): void => {
      state.isLoading = false;
      state.permissions.push(action.payload.data);
      state.total = state.total + 1;
      useMsgSuccess("تم إضافة صلاحية بنجاح");
    });
    builder.addCase(createBermission.rejected, (state, action): void => {
      state.isLoading = false;
    });

    // [3] => Update permission.
    builder.addCase(updateBermission.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(updateBermission.fulfilled, (state, action): void => {
      state.isLoading = false;
      state.permissions = state.permissions.filter(
        (item) => item.id !== action.payload.data.id
      );
      state.permissions.push(action.payload.data);
      useMsgSuccess("تم التعديل صلاحية بنجاح");
    });
    builder.addCase(updateBermission.rejected, (state, action): void => {
      state.isLoading = false;
    });

    // [4] => Delete permission.
    builder.addCase(deleteBermission.pending, (state, action): void => {});
    builder.addCase(deleteBermission.fulfilled, (state, action): void => {
      state.permissions = state.permissions.filter(
        (item) => item.id !== action.payload
      );
      state.total = state.total - 1;
      useMsgSuccess("تم حذف الصلاحية بنجاح");
    });
    builder.addCase(deleteBermission.rejected, (state, action): void => {});
  },
});

export default permissionSlice.reducer;
export const { nextPage, prevPage } = permissionSlice.actions;

import { createSlice } from "@reduxjs/toolkit";

/*
  ===================
  ====== Slice ======
  ===================
*/

type initState = {
  formWork: { client_type: string };
  isError: boolean;
};

const initialState: initState = {
  formWork: {
    client_type: "",
  },
  isError: false,
};

const fromSliceWork = createSlice({
  name: "formWork",
  initialState: initialState,
  reducers: {
    // Set Date
    setFormWork: (state, action) => {
      state.formWork.client_type = action.payload;
    },

    // Set Error
    setIsError: (state) => {
      state.isError = true;
    },

    // Set Default
    setDefault: (state, action) => {
      if (action.payload === undefined) {
        state.formWork.client_type = "";
        state.isError = false;
      }
    },
  },
});

export default fromSliceWork.reducer;
export const { setFormWork, setIsError, setDefault } = fromSliceWork.actions;

import React, { useEffect } from "react";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";
import { useAppSelector } from "../../../hooks/store/useAppSelector";
import NestedPage from "../layoutSubRoute/layoutSubRoute";
import Tages from "../tages/Tages";
import {
  deleteWork,
  getAllWork,
  createWork,
} from "../../../store/clients/work/workSlice";
import {
  setFormWork,
  setIsError,
} from "../../../store/clients/work/formSliceWork";

const Work: React.FC = () => {
  const dispatch = useAppDispatch();
  const { works, isLoading } = useAppSelector((state) => state.work);
  const { formWork, isError } = useAppSelector((state) => state.fromWork);

  useEffect(() => {
    dispatch(getAllWork());
  }, [dispatch]);

  const submit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formWork.client_type === "") {
      dispatch(setIsError);
    } else {
      dispatch(createWork(formWork));
    }
  };

  const DATA = works.map((item: any) => (
    <Tages
      text={item.client_type}
      id={item.id}
      key={item.id}
      fun={deleteWork}
    />
  ));

  return (
    <NestedPage
      submit={submit}
      text="النشاط"
      value="إضافة نشاط"
      fun={setFormWork}
      error={isError && formWork.client_type === "" ? true : false}
      loading={isLoading}
    >
      <>{DATA}</>
    </NestedPage>
  );
};

export default Work;

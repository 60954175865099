import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/store/useAppSelector";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";
import { Roles } from "../../../api/roles";
import Input from "../form/Input";
import MultiSelect from "../form/MultiSelect";
import Form from "../form/Form";
import {
  setIsError,
  setName,
  setRoles,
} from "../../../store/users/permission/formSlicePermission";
import {
  createBermission,
  updateBermission,
} from "../../../store/users/permission/permissionSlice";

const FormPermission: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { formPermission, editForm, isError } = useAppSelector(
    (state) => state.formpermission
  );
  const { isLoading } = useAppSelector((state) => state.permission);

  const submit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formPermission.name === "" || formPermission.permission.length < 1) {
      dispatch(setIsError());
    } else {
      if (editForm) {
        dispatch(updateBermission(formPermission)).then(() =>
          navigate("/dashboard/permission", { replace: true })
        );
      } else {
        dispatch(createBermission(formPermission)).then(() =>
          navigate("/dashboard/permission", { replace: true })
        );
      }
    }
  };

  return (
    <Form submit={submit} route={"/dashboard/permission"}>
      <Input
        type="text"
        text="أسم الصلاحية"
        placeholder="Admin"
        readOnly={editForm}
        value={formPermission.name}
        fun={setName}
        error={isError && formPermission.name === ""}
      />
      <div className=" col-span-12">
        <MultiSelect
          data={Roles}
          editData={formPermission.permission}
          fun={setRoles}
          error={isError && formPermission.permission.length < 1}
        />
      </div>
      <Input
        type="submit"
        text={editForm ? "تعديل صلاحية" : "إضافة صلاحية"}
        loading={isLoading}
      />
    </Form>
  );
};

export default FormPermission;

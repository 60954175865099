import React, { useEffect } from "react";
import { useAppSelector } from "../../../hooks/store/useAppSelector";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";
import NestedPage from "../layoutSubRoute/layoutSubRoute";
import Tages from "../tages/Tages";
import {
  deleteNationality,
  getAllNationality,
  createNationality,
} from "../../../store/clients/nationality/nationalitySlice";
import {
  setFormNationality,
  setIsError,
} from "../../../store/clients/nationality/fromSliceNationality";

const Nationality: React.FC = () => {
  const dispatch = useAppDispatch();
  const { nationalities, isLoading } = useAppSelector(
    (state) => state.nationality
  );
  const { formNationality, isError } = useAppSelector(
    (state) => state.fromNationality
  );

  useEffect(() => {
    dispatch(getAllNationality());
  }, [dispatch]);

  const submit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formNationality.accountant_nationality === "") {
      dispatch(setIsError());
    } else {
      dispatch(createNationality(formNationality));
    }
  };

  const DATA = nationalities.map((item: any) => (
    <Tages
      text={item.accountant_nationality}
      id={item.id}
      key={item.id}
      fun={deleteNationality}
    />
  ));

  return (
    <NestedPage
      submit={submit}
      text="الجنسية"
      value="إضافة جنسية"
      fun={setFormNationality}
      error={
        isError && formNationality.accountant_nationality === "" ? true : false
      }
      loading={isLoading}
    >
      <>{DATA}</>
    </NestedPage>
  );
};

export default Nationality;

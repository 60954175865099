import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon } from "leaflet";
import { useAppSelector } from "../../../../hooks/store/useAppSelector";
import { useAppDispatch } from "../../../../hooks/store/useAppDispatch";
import { getAllLocationClients } from "../../../../store/clients/main/clientSlice";
import { faBagShopping, faBoxesStacked, faCompress } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setShowFullMap, setShowSwapMap } from "../../../../store/main/mainSlice";

const MapAdmin: React.FC = () => {
  const dispatch = useAppDispatch();
  const { locationsClient } = useAppSelector((state) => state.clients);
  const { showFullMap } = useAppSelector((state) => state.mainSlice);

  useEffect(() => {
    dispatch(getAllLocationClients());
  }, [dispatch]);

  // customIconDefault
  const customIconDefault = new Icon({
    iconUrl: "location-default.svg",
    iconSize: [38, 38], // size of the icon
  });

  return (
    <div
      className={` ${
        showFullMap
          ? " absolute top-0 left-0 h-screen w-screen transition-all duration-150 z-[10000000]"
          : "grid grid-cols-12 gap-4 p-1 rounded-md shadow-box-2 bg-eee relative"
      }`}
    >
      <div className=" absolute top-[77px] left-[14px] bg-white border-solid border-2 border-[#c2bfba] border-t-0 px-2 py-1 rounded-b-md z-[10000006] hover:bg-eee cursor-pointer" onClick={() => dispatch(setShowFullMap())}>
        <FontAwesomeIcon icon={faCompress} />
      </div>
      <div className=" absolute right-0 top-0 py-1 px-2 z-[1000] bg-blue text-white flex gap-4 text-sm">
        <div className=" flex gap-2 items-center cursor-pointer" onClick={() => dispatch(setShowSwapMap(true))}>
          <FontAwesomeIcon icon={faBoxesStacked} />
          <p>الطلبات</p>
        </div>
        <div className=" flex gap-2 items-center cursor-pointer" onClick={() => dispatch(setShowSwapMap(false))}>
          <FontAwesomeIcon icon={faBagShopping} />
          <p>العملاء</p>
        </div>
      </div>
      <div className={`${showFullMap ? " h-screen" : " col-span-12 h-96"}`}>
      <MapContainer
        center={[24.719161549111778, 46.68777056344977]}
        zoom={9}
        className={` h-full`}
      >
        <TileLayer
          {...{
            attribution:
              '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          }}
        />
        <MarkerClusterGroup chunkedLoading>
          {locationsClient.map((item: any) => (
            <Marker
              {...{
                position: [item.latitude, item.longitude],
                icon: customIconDefault,
              }}
              key={item.id}
            >
              <Popup>
                <div className=" flex gap-2 text-right text-[12px]">
                  <ul>
                    <li className=" p-[1px]">
                      <span>الاسم</span>
                    </li>
                    <li className=" p-[1px]">
                      <span>رقم الجوال</span>
                    </li>
                  </ul>
                  <ul>
                    <li className=" p-[1px]">
                      <span>{item.name}</span>
                    </li>
                    <li className=" p-[1px]">
                      <span>{item.phone}</span>
                    </li>
                  </ul>
                </div>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
      </div>
    </div>
  );
};

export default MapAdmin;

import React from "react";
import { Link } from "react-router-dom";

// props component
interface prop {
  total: number;
  text: string;
  icon: any;
  path: string;
  bg: string;
}

const Card: React.FC<prop> = ({ total, icon, text, path, bg }) => {
  return (
    <Link
      to={path}
      className={` col-span-12 md:col-span-4 h-36 px-2 py-4 flex flex-col items-center gap-4 rounded-md shadow-box-2 text-xl font-medium overflow-hidden cursor-pointer text-white ${bg} transition-all duration-100 hover:opacity-70`}
    >
      <div className=" flex gap-2 items-center">
        {icon}
        <p>{text}</p>
      </div>
      <div className=" flex-1 flex justify-center items-center text-2xl">
        {total}
      </div>
    </Link>
  );
};

export default Card;

import React from "react";

// => Props Component
interface prop {
  css: string;
  src: string;
  alt: string;
}

const Img: React.FC<prop> = ({ css, src, alt }) => {
  return (
    <div className={`${css} overflow-hidden`}>
      <img className=" w-full h-auto" src={src} alt={alt} />
    </div>
  );
};

export default Img;

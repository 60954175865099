import React, { useEffect } from "react";
import { useAppDispatch } from "../../../../hooks/store/useAppDispatch";
import { useAppSelector } from "../../../../hooks/store/useAppSelector";
import {
  getTotalClient,
  getTotalOrders,
  getTotalUsers,
} from "../../../../store/admin/dashboardSlice";
import Card from "../Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faBox,
  faFileInvoice,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

const TotalData: React.FC = () => {
  const dispatch = useAppDispatch();
  const { totalClients, totalOrders, totalUsers } = useAppSelector(
    (state) => state.dashboard
  );

  useEffect(() => {
    dispatch(getTotalClient());
    dispatch(getTotalUsers());
    dispatch(getTotalOrders());
  }, [dispatch]);

  return (
    <div className="grid grid-cols-12 gap-4">
      <Card
        total={totalClients}
        icon={<FontAwesomeIcon icon={faBagShopping} />}
        text={"العملاء"}
        path={"/dashboard/clients"}
        bg="bg-[#52b9d8]"
      />
      <Card
        total={totalUsers}
        icon={<FontAwesomeIcon icon={faUsers} />}
        text={"المستخدمين"}
        path={"/dashboard/users"}
        bg="bg-[#f8cb55]"
      />
      <Card
        total={totalOrders}
        icon={<FontAwesomeIcon icon={faBox} />}
        text={"الطلبات"}
        path={"/dashboard/order"}
        bg="bg-[#ed7ca8]"
      />
    </div>
  );
};

export default TotalData;

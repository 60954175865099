import React from "react";
import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

// Store Redux Tookit Import
import store from "./store";

// NotFound Page Import
import NotFounded from "./pages/NotFounded/Index";

// Login Page Import
import Login from "./pages/auth/Login";

// Dashboard Imports
import Dashboard from "./pages/dashboard/Dashboard";
import Users from "./pages/dashboard/Users";
import Clients from "./pages/dashboard/Clients";
import Settings from "./pages/dashboard/Settings";

// WebSite Imports
import Landing from "./pages/web/Index";
import Permission from "./pages/dashboard/Permission";
import Area from "./components/dashboard/clients/area";
import Nationality from "./components/dashboard/clients/nationality";
import Work from "./components/dashboard/clients/work";
import ViewClients from "./components/dashboard/clients/ViewClients";
import ViewUsers from "./components/dashboard/users/ViewUsers";
import ViewPermission from "./components/dashboard/permission/ViewPermission";
import { ToastContainer } from "react-toastify";
import Oredr from "./pages/dashboard/Oredr";
import ViewOrder from "./components/dashboard/order/ViewInvoice";
import FormUser from "./components/dashboard/users/FormUser";
import FormPermission from "./components/dashboard/permission/FormPermission";
import FormClient from "./components/dashboard/clients/FormClient";
import FormInvoice from "./components/dashboard/order/FormInvoice";

// LazyLoad for Dashboard
const LazyDash = React.lazy(() => import("./pages/dashboard/Layout"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Router
const routes = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <NotFounded />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: (
      <React.Suspense fallback="Loading...">
        <LazyDash />
      </React.Suspense>
    ),
    children: [
      { index: true, element: <Dashboard /> },
      {
        path: "users",
        element: <Users />,
        children: [
          { index: true, element: <ViewUsers /> },
          { path: "form-user", element: <FormUser /> },
        ],
      },
      {
        path: "clients",
        element: <Clients />,
        children: [
          { index: true, element: <ViewClients /> },
          { path: "form-client", element: <FormClient /> },
          { path: "add-work", element: <Work /> },
          { path: "add-nationality", element: <Nationality /> },
          { path: "add-area", element: <Area /> },
        ],
      },
      {
        path: "order",
        element: <Oredr />,
        children: [
          { index: true, element: <ViewOrder /> },
          { path: "form-invoice", element: <FormInvoice /> },
        ],
      },
      { path: "settings", element: <Settings /> },
      {
        path: "permission",
        element: <Permission />,
        children: [
          { index: true, element: <ViewPermission /> },
          { path: "form-permission", element: <FormPermission /> },
        ],
      },
    ],
  },
]);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={routes} />
      <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      <ToastContainer style={{ zIndex: "1000000000", right: "0" }} />
    </QueryClientProvider>
  </Provider>
  </React.StrictMode>
);

reportWebVitals();

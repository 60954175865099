import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";
import Loading from "../loading/Loading";

interface prop {
  type: string;
  text: string;
  min?: number;
  value?: number | string;
  error?: boolean;
  placeholder?: string;
  fun?: any;
  loading?: boolean;
  readOnly?: boolean;
}

const Input: React.FC<prop> = ({
  type,
  text,
  error,
  min,
  value,
  placeholder,
  fun,
  loading,
  readOnly,
}) => {
  const dispatch = useAppDispatch();

  const showInput =
    type === "submit" ? (
      <label className="col-span-12 flex justify-center items-center h-14 gap-x-1 rounded-sm bg-[#4A5CB6] text-white text-xl cursor-pointer hover:opacity-80 mt-6 mb-4 transition-all duration-150">
        <input type={type} value={text} className=" cursor-pointer" />
        {loading && <Loading />}
      </label>
    ) : type === "file" ? (
      <label className="col-span-12 md:col-span-6 flex flex-col gap-y-1">
        <p className="text-lg">{text}</p>
        <input
          className={
            error
              ? `w-full h-11 p-2 rounded-sm bg-white border-[#D9003F] border-solid border-2 outline-none`
              : `w-full h-11 p-2 rounded-sm bg-white outline-[#4A5CB6] outline-2 border-2`
          }
          type={type}
          min={min}
          value={value}
          placeholder={placeholder || text}
          readOnly={readOnly}
          onChange={(e: any) => dispatch(fun(e.target.files[0]))}
        />
        {error && (
          <div className=" flex items-center gap-2 text-[#D9003F] text-sm">
            <FontAwesomeIcon icon={faCircleInfo} />
            <p>{`من فضلك قم بإدخال ${text}`}</p>
          </div>
        )}
      </label>
    ) : (
      <label className="col-span-12 md:col-span-6 flex flex-col gap-y-1">
        <p className="text-lg">{text}</p>
        <input
          className={
            error
              ? `w-full h-11 p-2 rounded-sm text-lg text-blue bg-white border-[#D9003F] border-solid border-2 outline-none`
              : `w-full h-11 p-2 rounded-sm text-lg text-blue bg-white outline-[#4A5CB6] outline-2 border-2`
          }
          type={type}
          min={min}
          value={value}
          placeholder={placeholder || text}
          readOnly={readOnly}
          onChange={(e) => dispatch(fun(e.target.value))}
        />
        {error && (
          <div className=" flex items-center gap-2 text-[#D9003F] text-sm">
            <FontAwesomeIcon icon={faCircleInfo} />
            <p>{`من فضلك قم بإدخال ${text}`}</p>
          </div>
        )}
      </label>
    );
  return <>{showInput}</>;
};

export default Input;

import React, { useEffect } from "react";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";
import { useAppSelector } from "../../../hooks/store/useAppSelector";
import NestedPage from "../layoutSubRoute/layoutSubRoute";
import Tages from "../tages/Tages";
import {
  deleteArea,
  getAllArea,
  createArea,
} from "../../../store/clients/area/areaSlice";
import { setName, setisError } from "../../../store/clients/area/formSliceArea";

const Area: React.FC = () => {
  const dispatch = useAppDispatch();
  const { areas, isLoading } = useAppSelector((state) => state.areas);
  const { formArea, isError } = useAppSelector((state) => state.formArea);

  useEffect(() => {
    dispatch(getAllArea());
  }, [dispatch]);

  const submit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formArea.area_name === "") {
      dispatch(setisError());
    } else {
      dispatch(createArea(formArea));
    }
  };

  const DATA = areas.map((item: any) => (
    <Tages text={item.area_name} id={item.id} key={item.id} fun={deleteArea} />
  ));

  return (
    <NestedPage
      submit={submit}
      text="المنطقة"
      value="إضافة منطقة"
      fun={setName}
      error={isError && formArea.area_name === "" ? true : false}
      loading={isLoading}
    >
      <>{DATA}</>
    </NestedPage>
  );
};

export default Area;

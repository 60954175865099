import React from "react";
import useGaurd from "../../hooks/dashboard/useGaurd";
import InformationForCompany from "../../components/auth/InformationForCompany";
import FormLogin from "../../components/auth/FormLogin";

const Login: React.FC = () => {
  return (
    <div className=" h-screen w-screen py-5 px-2 md:py-16 flex items-center bg-eee select-none">
      <div className=" container h-fit md:h-full flex flex-col-reverse md:flex-row rounded-md overflow-hidden shadow-box">
        <div className=" md:flex-[50%] px-4 py-5 md:px-6 lg:px-16 flex flex-col justify-center gap-y-6 md:gap-y-7 bg-white">
          <FormLogin />
        </div>
        <div className=" md:flex-[50%] px-2 py-5 flex justify-center items-center bg-f3f4f8 relative">
          <InformationForCompany version={"2.0.0"} />
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useGaurd(Login);

import React from "react";

const Settings: React.FC = () => {
  return (
    <>
      <div>
        <h2 className="lg:text-lg font-bold">الاعدادات</h2>
      </div>
      <div className=" flex-1"></div>
    </>
  );
};

export default Settings;

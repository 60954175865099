import React from "react";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";

interface prop {
  data: any;
  fun: any;
  editData: any;
  error: boolean;
}


const MultiSelect: React.FC<prop> = ({ data, fun, editData, error }) => {
  const dispatch = useAppDispatch();

  const DATA = data.map((item: string) => {
    return (
      <label className=" flex gap-x-1 cursor-pointer" key={item}>
        <input
          type="checkbox"
          value={item}
          checked={editData.includes(item)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            dispatch(fun({ check: e.target.checked, val: item }))
          }
        />
        <p> {item} </p>
      </label>
    );
  });

  return (
    <div className={` flex flex-wrap gap-y-1 gap-x-4 border ${error? " border-red" : "border-eee"} bg-eee  p-3 rounded-sm`}>
      {DATA}
    </div>
  );
};

export default MultiSelect;

import { createSlice } from "@reduxjs/toolkit";

/*
  ===================
  ====== Slice ======
  ===================
*/

interface mainState {
  showSidbar: boolean;
  showTypeWork: boolean;
  showTypeNationality: boolean;
  showTypeArea: boolean;
  showCallTime: boolean;
  showDelvieryTime: boolean;
  showPermission: boolean;
  showFilterWork: boolean;
  showFilterArea: boolean;
  showStatusOrder: boolean;
  showDriverOrder: boolean;
  showStatusOrderInMap: boolean;
  showDriversInMap: boolean;
  showControleOrderInMap: boolean;
  showFilterInvoice: boolean;
  showstatusPaying: boolean;
  showFullMap: boolean;
  showSwapMap: boolean;
}

const initialState: mainState = {
  showSidbar: false,
  showTypeWork: false,
  showTypeNationality: false,
  showTypeArea: false,
  showCallTime: false,
  showDelvieryTime: false,
  showPermission: false,
  showFilterWork: false,
  showFilterArea: false,
  showStatusOrder: false,
  showDriverOrder: false,
  showStatusOrderInMap: false,
  showDriversInMap: false,
  showControleOrderInMap: false,
  showFilterInvoice: false,
  showstatusPaying: false,
  showFullMap: false,
  showSwapMap: false
};

const mainSlice = createSlice({
  name: "mainSlice",
  initialState: initialState,
  reducers: {
    setShowSidbar: (state) => {
      state.showSidbar = !state.showSidbar;
    },
    setShowTypeWork: (state) => {
      state.showTypeWork = !state.showTypeWork;
    },
    setShowTypeNationality: (state) => {
      state.showTypeNationality = !state.showTypeNationality;
    },
    setShowTypeArea: (state) => {
      state.showTypeArea = !state.showTypeArea;
    },
    setShowCallTime: (state) => {
      state.showCallTime = !state.showCallTime;
    },
    setShowDelvieryTime: (state) => {
      state.showDelvieryTime = !state.showDelvieryTime;
    },
    setShowPermission: (state) => {
      state.showPermission = !state.showPermission;
    },
    setShowFilterWork: (state) => {
      state.showFilterWork = !state.showFilterWork;
    },
    setShowFilterArea: (state) => {
      state.showFilterArea = !state.showFilterArea;
    },
    setShowStatusOrder: (state) => {
      state.showStatusOrder = !state.showStatusOrder;
    },
    setShowDriverOrder: (state) => {
      state.showDriverOrder = !state.showDriverOrder;
    },
    setShowStatusOrderInMap: (state) => {
      state.showStatusOrderInMap = !state.showStatusOrderInMap;
    },
    setShowDriversInMap: (state) => {
      state.showDriversInMap = !state.showDriversInMap;
    },
    setShowControleOrderInMap: (state) => {
      state.showControleOrderInMap = !state.showControleOrderInMap;
    },
    setShowFilterInvoice: (state) => {
      state.showFilterInvoice = !state.showFilterInvoice
    },
    setShowstatusPaying: (state) => {
      state.showstatusPaying = !state.showstatusPaying;
    },
    setShowFullMap: (state) => {
      state.showFullMap = !state.showFullMap;
    },
    setShowSwapMap: (state, action) => {
      state.showSwapMap = action.payload;
    }
  },
});

export default mainSlice.reducer;
export const {
  setShowSidbar,
  setShowTypeWork,
  setShowTypeNationality,
  setShowTypeArea,
  setShowCallTime,
  setShowDelvieryTime,
  setShowPermission,
  setShowFilterWork,
  setShowFilterArea,
  setShowStatusOrder,
  setShowDriverOrder,
  setShowStatusOrderInMap,
  setShowControleOrderInMap,
  setShowDriversInMap,
  setShowFilterInvoice,
  setShowstatusPaying,
  setShowFullMap,
  setShowSwapMap
} = mainSlice.actions;

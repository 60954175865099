import { faCircleInfo, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";


const Select: React.FC<any> = ({
  text,
  error,
  children
}) => {



  return (
    <div className="col-span-12 md:col-span-6 flex flex-col gap-y-1">
      <div>
        <p className="text-lg">{text}</p>
      </div>
      <div
        className={
          error
            ? `col-span-12 md:col-span-6 flex gap-x-3 lg:gap-x-6 w-full h-11 p-2 rounded-sm bg-white border-2 border-[#D9003F]`
            : `col-span-12 md:col-span-6 flex gap-x-3 lg:gap-x-6 w-full h-11 p-2 rounded-sm bg-white border-2`
        }
      >
        {children}
      </div>
      {error && (
        <div className=" flex items-center gap-2 text-[#D9003F] text-sm">
          <FontAwesomeIcon icon={faCircleInfo} />
          <p className=" text-[#D9003F] text-sm">{`من فضلك قم بإختيار ${text}`}</p>
        </div>
      )}
    </div>
  );
};

export default Select;

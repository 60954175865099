/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import { useAppSelector } from "../../../hooks/store/useAppSelector";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";
import { useNavigate } from "react-router-dom";
import Form from "../form/Form";
import Input from "../form/Input";
import Search from "../form/Search";
import DropMenu from "../form/DropMenu";
import {
  createInvoice,
  getAllDrivers,
  updateInvoice,
} from "../../../store/order/invoiceSlice";
import {
  searchClientByName,
  setClientName,
  setClientSelect,
  setDriverId,
  setInvoiceNumber,
  setIsError,
  setNumberPackages,
  setStatus,
  setStatusPay,
} from "../../../store/order/formSliceOrder";
import {
  setShowDriverOrder,
  setShowStatusOrder,
  setShowstatusPaying,
} from "../../../store/main/mainSlice";
import { useMsgSuccess } from "../../../hooks/toastify/notification";

const FormInvoice: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showStatusOrder, showDriverOrder, showstatusPaying } = useAppSelector(
    (state) => state.mainSlice
  );

  const { isLoading, allDrivers } = useAppSelector((state) => state.order);
  const { clientName, formOrder, isError, editForm, clientsResult } =
    useAppSelector((state) => state.formOrder);

  useEffect(() => {
    const time = setTimeout(() => {
      dispatch(searchClientByName(clientName));
    }, 1200);

    return () => clearTimeout(time);
  }, [clientName, dispatch]);

  useEffect(() => {
    dispatch(getAllDrivers());
  }, [dispatch]);

  const submit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formOrder.invoice_number === 0 || formOrder.client_id === -1) {
      dispatch(setIsError());
    } else {
      if (editForm) {
        console.log(formOrder.status)
        if (formOrder.status < 5) {
          dispatch(updateInvoice({...formOrder, payment_status: 0})).then((res) => {
            if (res.payload.successful) {
              useMsgSuccess("تم التعديل بنجاح");
              navigate("/dashboard/order");
            }
          });
        }else {
          dispatch(updateInvoice(formOrder)).then((res) => {
            if (res.payload.successful) {
              useMsgSuccess("تم التعديل بنجاح");
              navigate("/dashboard/order");
            }
          });
        }
      } else {
        dispatch(createInvoice(formOrder)).then((res) => {
          if (res.payload.successful) navigate("/dashboard/order");
        });
      }
    }
  };

  const statusOrder = [
    { id: 0, name: "تم رفع الطلب" },
    { id: 1, name: "تم اعتماد الطلب" },
    { id: 2, name: "يتم تحضير الطلب" },
    { id: 3, name: "الطلب مع السائق" },
    { id: 4, name: "تم تسليم الطلب" },
    { id: 5, name: "تم استلام المبلغ" },
    { id: 6, name: "العميل كنسل الطلب" },
    { id: 7, name: "تم تقفيل الطلب" },
  ].map((item: any) => (
    <label
      className=" flex flex-row-reverse items-center justify-end gap-1 cursor-pointer"
      key={item.id}
    >
      <p className=" text-md">{item.name}</p>
      <input
        type="radio"
        value={item.id}
        name="__oredrsInvoice"
        checked={formOrder.status === item.id}
        onChange={() => dispatch(setStatus(item.id))}
      />
    </label>
  ));

  const statusPoll = [
    { id: 0, name: "لم يتم الاستلام"},
    { id: 1, name: "كاش" },
    { id: 2, name: "شبكة"},
    { id: 3, name: "تحويل"},
    { id: 4, name: "آجل"}
  ].map((item: any) => (
    <label
      className=" flex flex-row-reverse items-center justify-end gap-1 cursor-pointer"
      key={item.id}
    >
      <p className=" text-md">{item.name}</p>
      <input
        type="radio"
        value={item.id}
        name="__oredrsstatusPay"
        checked={formOrder.payment_status === item.id}
        onChange={() => dispatch(setStatusPay(item.id))}
      />
    </label>
  ));


  const DriversAll = allDrivers.map((item: any) => (
    <label
      className=" flex flex-row-reverse items-center justify-end gap-1 cursor-pointer"
      key={item.id}
    >
      <p className=" text-md">{item.name}</p>
      <input
        type="radio"
        value={item.id}
        name="__driversAllOrders"
        checked={formOrder.driver_id === item.id}
        onChange={() => dispatch(setDriverId(item.id))}
      />
    </label>
  ));

  return (
    <Form submit={submit} route="/dashboard/order">
      <Input
        type="number"
        text="رقم الفاتورة"
        placeholder="00000000"
        value={formOrder.invoice_number === 0 ? "" : formOrder.invoice_number}
        fun={setInvoiceNumber}
        error={isError && formOrder.invoice_number === 0}
      />
            <Input
        type="number"
        text="عدد الطرود"
        placeholder="0"
        value={formOrder.number_of_packages === 0 ? "" : formOrder.number_of_packages}
        fun={setNumberPackages}
        error={isError && formOrder.number_of_packages=== 0}
      />
      <Search
        text="العميل"
        error={isError && formOrder.client_id === -1}
        placeholder={"اسم العميل..."}
        fun={setClientName}
        data={clientsResult}
        nestFun={setClientSelect}
        clientName={clientName}
        top="top-17"
      />
      {editForm && (
        <>
          <DropMenu
            type="radio"
            text="حالة الطلب"
            fun={setShowStatusOrder}
            action={showStatusOrder}
          >
            {statusOrder}
          </DropMenu>
          <DropMenu
            type="radio"
            text="السائق"
            fun={setShowDriverOrder}
            action={showDriverOrder}
          >
            {DriversAll}
          </DropMenu>
          {
            formOrder.status >= 5 && 
          <DropMenu
            type="radio"
            text="طريقة الاستلام"
            fun={setShowstatusPaying}
            action={showstatusPaying}
          >
            {statusPoll}
          </DropMenu>
          }
        </>
      )}
      <Input
        type="submit"
        text={editForm ? "تعديل فاتورة" : "إضافة فاتورة"}
        loading={isLoading}
      />
    </Form>
  );
};

export default FormInvoice;

import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";

const Form: React.FC<any> = ({ submit, children, route }) => {
  const navigate = useNavigate();
  return (
    <div className=" h-vh-minus-52 overflow-y-scroll">
      <button
        className=" flex items-center gap-2 text-sm md:text-lg w-fit py-1 px-2 md:px-4 bg-blue hover:opacity-70 text-white rounded-sm shadow-box-2 transition-all duration-100 absolute top-3 md:top-4 left-10 md:left-4 "
        onClick={() => navigate(route, { replace: true })}
      >
        <p>رجوع</p>
        <FontAwesomeIcon icon={faAnglesLeft} />
      </button>
      <form className="grid grid-cols-12 gap-x-4 gap-y-2" onSubmit={submit}>
        {children}
      </form>
    </div>
  );
};

export default Form;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { End_SEARCH, URL_BASEURL } from "../../api/api";
import Cookie from "cookie-universal";
import axios from "axios";
const cookie = Cookie();

/*
  ==============================
  ====== CreateAsyncThunk ======
  ==============================
*/

// [1] => Search Client by Name.
export const searchClientByName = createAsyncThunk(
  "formOrder/search",
  async (nameClient: string, thunkAPI) => {
    try {
      const res = await axios
        .get(`${URL_BASEURL}${End_SEARCH}/${nameClient}`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/*
  ===================
  ====== Slice ======
  ===================
*/

type initSTate = {
  clientsResult: any[];
  formOrder: any;
  isError: boolean;
  editForm: boolean;
  clientName: string;
};

const initialState: initSTate = {
  clientsResult: [],
  formOrder: {
    invoice_number: 0,
    client_id: -1,
    status: 0,
    driver_id: undefined,
    number_of_packages: 0,
    payment_status: 0,
  },
  isError: false,
  editForm: false,
  clientName: "",
};

const formSliceOrder = createSlice({
  name: "formOrder",
  initialState: initialState,
  reducers: {
    // Set Data.
    setInvoiceNumber: (state, action) => {
      state.formOrder.invoice_number = action.payload;
    },
    setClientSelect: (state, action) => {
      state.formOrder.client_id = action.payload.id;
      state.clientName = action.payload.name;
    },
    setStatus: (state, action) => {
      state.formOrder.status = action.payload;
    },
    setDriverId: (state, action) => {
      state.formOrder.driver_id = action.payload;
    },
    setNumberPackages: (state, action) => {
      state.formOrder.number_of_packages = action.payload;
    },
    setStatusPay: (state,action) => {
      state.formOrder.payment_status = action.payload;
    },
    setClientName: (state, action) => {
      state.clientName = action.payload;
    },
    setOrderFromMap: (state, action) => {
      state.formOrder.id = action.payload.id;
      state.formOrder.invoice_number =  action.payload.invoice_number;
      state.formOrder.client_id = action.payload.client_id.id;
      state.formOrder.number_of_packages = action.payload.number_of_packages;
      state.formOrder.status = 3;
      state.formOrder.driver_id =  action.payload.driver_id.id;
    },

    // Set Error.
    setIsError: (state) => {
      state.isError = true;
    },

    // Set Default.
    setDefalt: (state, action) => {
      if (action.payload === undefined) {
        state.editForm = false;
        state.clientsResult = [];
        state.formOrder.id = undefined;
        state.formOrder.client_id = -1;
        state.formOrder.invoice_number = 0;
        state.formOrder.status = 0;
        state.formOrder.driver_id = undefined;
        state.formOrder.number_of_packages = 0;
        state.clientName = "";
        state.isError = false;
      } else {
        state.editForm = true;
        state.clientsResult = [];
        state.formOrder.id = action.payload.id;
        state.formOrder.driver_id = action.payload.driver_id.id;
        state.formOrder.client_id = action.payload.client_id.id;
        state.formOrder.invoice_number = action.payload.invoice_number;
        state.formOrder.number_of_packages = action.payload.number_of_packages;
        state.formOrder.status = action.payload.status;
        state.clientName = action.payload.client_id.name;
        state.isError = false;
      }
    },
  },
  extraReducers: (builder) => {
    // [1] => Search Client by Name.
    builder.addCase(searchClientByName.pending, (state, action) => {});
    builder.addCase(searchClientByName.fulfilled, (state, action) => {
      if (action.payload.successful) {
        state.clientsResult = action.payload.data;
      }
    });
    builder.addCase(searchClientByName.rejected, (state, action) => {});
  },
});

export default formSliceOrder.reducer;
export const {
  setInvoiceNumber,
  setClientSelect,
  setStatus,
  setDriverId,
  setIsError,
  setClientName,
  setOrderFromMap,
  setDefalt,
  setNumberPackages,
  setStatusPay
} = formSliceOrder.actions;

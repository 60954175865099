import { faCircleInfo, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";

interface prop {
  text?: string;
  error: boolean;
  placeholder: string;
  fun: any;
  nestFun?: any;
  data: any;
  clientName?: any;
  height?: string;
  top?: string;
}

const Search: React.FC<prop> = ({
  text,
  error,
  placeholder,
  fun,
  nestFun,
  data,
  clientName,
  height,
  top
}) => {
  const dispatch = useAppDispatch();

  const DATA = data.map((item: any) => (
    <li
      className=" cursor-pointer hover:bg-[#eee] p-1 rounded-sm"
      onClick={() => dispatch(nestFun(item))}
      key={item.id}
    >
      <p>{item.name}</p>
    </li>
  ));

  return (
    <div className={`col-span-12 ${height} md:col-span-6 relative`}>
      <label className="flex flex-col gap-y-1">
        <p className="text-lg">{text}</p>
        <input
          className={
            error
              ? `w-full ${height} p-2 rounded-sm bg-white border-[#D9003F] border-solid border-2 outline-none`
              : `w-full ${height} p-2 rounded-sm bg-white outline-[#4A5CB6] outline-2 border-2`
          }
          type="text"
          placeholder={placeholder || text}
          value={clientName}
          onChange={(e) => dispatch(fun(e.target.value))}
        />
        {error && (
          <div className=" flex items-center gap-2 text-[#D9003F] text-sm">
            <FontAwesomeIcon icon={faCircleInfo} />
            <p>{`من فضلك قم بإدخال ${text}`}</p>
          </div>
        )}
      </label>
      <div
        className={` ${
          data.length < 1 || data[0].name === clientName ? "hidden" : ""
        } w-full bg-white absolute ${top} border-solid border-2 outline-none p-1 z-10 max-h-52 overflow-x-hidden overflow-y-scroll`}
      >
        <ul className=" flex flex-col gap-1 text-sm">{DATA}</ul>
      </div>
    </div>
  );
};

export default Search;

import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

const Date: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(dayjs().format("YYYY-MM-DD"));

  // get Date
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(dayjs().format("YYYY-MM-DD"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className=" me-8 md:me-0 text-sm flex justify-center items-center gap-x-4 py-2 px-4 md:text-lg bg-eee rounded-md shadow-box">
      <p>{currentDate}</p>
      <FontAwesomeIcon icon={faCalendarDays} />
    </div>
  );
};

export default Date;

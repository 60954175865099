export const URL_BASEURL: string = "http://test.ramz-aluqud.com/";
export const End_LOGIN: string = "api/auth/login";
export const End_LOGOUT: string = "api/auth/logout";
export const End_CLIENTS: string = "api/clients";
export const End_USERS: string = "api/users";
export const End_ROLES: string = "api/roles";
export const End_FILTER: string = "api/filter";
export const End_WORKS: string = "api/clientTypes";
export const End_NATIONALITY: string = "api/nationalities";
export const End_SEARCH: string = "api/clients/search";
export const End_IMAGE: string = "storage/uploads/";
export const End_AREAS: string = "api/areas";

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/store/useAppSelector";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";
import Input from "../form/Input";
import DropMenu from "../form/DropMenu";
import Form from "../form/Form";
import { getAllBermission } from "../../../store/users/permission/permissionSlice";
import { setShowPermission } from "../../../store/main/mainSlice";
import {
  setEmail,
  setIsError,
  setName,
  setPassword,
  setRoles,
} from "../../../store/users/main/formSliceUser";
import { createUser, updateUser } from "../../../store/users/main/usersSlice";

const FormUser: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { showPermission } = useAppSelector((state) => state.mainSlice);
  const { formUser, isError, editForm } = useAppSelector(
    (state) => state.formUsers
  );
  const { isLoading } = useAppSelector((state) => state.users);
  const { permissions } = useAppSelector((state) => state.permission);

  useEffect(() => {
    dispatch(getAllBermission(1));
  }, [dispatch]);

  const submit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      formUser.name === "" ||
      formUser.email === "" ||
      formUser.password === "" ||
      formUser.password.length < 8 ||
      formUser.roles_name.length < 1
    ) {
      dispatch(setIsError());
    } else {
      if (editForm) {
        dispatch(updateUser(formUser)).then((res) => {
          if (res.payload.status) {
            navigate("/dashboard/users", { replace: true });
          }
        });
      } else {
        dispatch(createUser(formUser)).then((res) => {
          if (res.payload.status) {
            navigate("/dashboard/users", { replace: true });
          }
        });
      }
    }
  };

  const DATA = permissions.map((item: any) => (
    <label
      className=" flex flex-row-reverse items-center justify-end gap-1 cursor-pointer"
      key={item.id}
    >
      <p className=" text-md">{item.name}</p>
      <input
        type="radio"
        value={item.id}
        name="__permissions"
        checked={item.name === formUser.roles_name[0]}
        onChange={() => dispatch(setRoles(item.name))}
      />
    </label>
  ));

  return (
    <Form submit={submit} route="/dashboard/users">
      <Input
        type="text"
        text="الاسم"
        placeholder="إبراهيم كساب"
        value={formUser.name}
        fun={setName}
        error={isError && formUser.name === ""}
      />
      <Input
        type="email"
        text="الإيميل"
        placeholder="ibr_itqan@gmail.com"
        value={formUser.email}
        fun={setEmail}
        error={isError && formUser.email === ""}
      />
      <Input
        type="password"
        text="كلمة المرور"
        placeholder="********"
        fun={setPassword}
        error={
          isError && (formUser.password === "" || formUser.password.length < 8)
        }
      />
      <DropMenu
        type="radio"
        text="صلاحية المستخدم"
        fun={setShowPermission}
        action={showPermission}
        error={isError && formUser.roles_name.length < 1}
      >
        {DATA}
      </DropMenu>
      <Input
        type="submit"
        text={editForm ? "تعديل مستخدم" : "إضافة مستخدم"}
        loading={isLoading}
      />
    </Form>
  );
};

export default FormUser;

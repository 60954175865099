import React, { useEffect } from "react";
import { useAppSelector } from "../../../hooks/store/useAppSelector";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";
import { useNavigate } from "react-router-dom";
import Input from "../form/Input";
import Form from "../form/Form";
import DropMenu from "../form/DropMenu";
import Select from "../form/Select";
import { getAllArea } from "../../../store/clients/area/areaSlice";
import { getAllNationality } from "../../../store/clients/nationality/nationalitySlice";
import { getAllWork } from "../../../store/clients/work/workSlice";
import {
  createClient,
  updateClient,
} from "../../../store/clients/main/clientSlice";
import {
  setCallTime,
  setClosingTime,
  setDeliveryTime,
  setGPS,
  setIs24Hours,
  setLocationDescription,
  setName,
  setNumberOfUnits,
  setOpeningTime,
  setPhone,
  setPhone2,
  setShopPhoto,
  setSignaturePhoto,
  setTypeArea,
  setTypeNationalit,
  setTypeStreet,
  setTypeWorkId,
  setisError,
} from "../../../store/clients/main/formSliceClient";
import {
  setShowTypeArea,
  setShowTypeNationality,
  setShowTypeWork,
} from "../../../store/main/mainSlice";

const FormClient: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { formClient, isError, editForm } = useAppSelector(
    (state) => state.formClient
  );
  const { showTypeWork, showTypeNationality, showTypeArea } = useAppSelector(
    (state) => state.mainSlice
  );
  const { isLoading } = useAppSelector((state) => state.clients);
  const { works } = useAppSelector((state) => state.work);
  const { nationalities } = useAppSelector((state) => state.nationality);
  const { areas } = useAppSelector((state) => state.areas);

  useEffect(() => {
    dispatch(getAllArea());
    dispatch(getAllNationality());
    dispatch(getAllWork());
  }, [dispatch]);

  const submit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      formClient.name === "" ||
      formClient.phone === "" ||
      formClient.latitude === "" ||
      formClient.longitude === "" ||
      formClient.location_description === "" ||
      formClient.number_of_units === 0 ||
      formClient.opening_time === "" ||
      formClient.closing_time === "" ||
      formClient.signature_photo === "" ||
      formClient.shop_photo === ""
    ) {
      dispatch(setisError());
    } else {
      if (editForm) {
        dispatch(updateClient(formClient)).then((res) => {
          if (res.payload.successful)
            navigate("/dashboard/clients", { replace: true });
        });
      } else {
        dispatch(createClient(formClient)).then((res) => {
          console.log(res.payload);
          if (res.payload.successful)
            navigate("/dashboard/clients", { replace: true });
        });
      }
    }
  };

  const time = ["الفجر", "الظهر", "العصر", "المغرب", "العشاء"];

  const DataWork = works.map((item: any) => (
    <label
      className=" flex flex-row-reverse items-center justify-end gap-1 cursor-pointer"
      key={item.id}
    >
      <p className=" text-md">{item.client_type}</p>
      <input
        type="radio"
        value={item.id}
        name="__permissions"
        checked={formClient.client_type_id === item.id}
        onChange={() => dispatch(setTypeWorkId(item.id))}
      />
    </label>
  ));

  const DataNationality = nationalities.map((item: any) => (
    <label
      className=" flex flex-row-reverse items-center justify-end gap-1 cursor-pointer"
      key={item.id}
    >
      <p className=" text-md">{item.accountant_nationality}</p>
      <input
        type="radio"
        value={item.id}
        name="__nationalities"
        checked={item.id === formClient.accountant_nationality_id}
        onChange={() => dispatch(setTypeNationalit(item.id))}
      />
    </label>
  ));

  const DataArea = areas.map((item: any) => (
    <label
      className=" flex flex-row-reverse items-center justify-end gap-1 cursor-pointer"
      key={item.id}
    >
      <p className=" text-md">{item.area_name}</p>
      <input
        type="radio"
        value={item.id}
        name="__areas"
        checked={item.id === formClient.area_id}
        onChange={() => dispatch(setTypeArea(item.id))}
      />
    </label>
  ));

  const CallTime = time.map((item: any) => (
    <label
      className=" flex flex-row-reverse justify-center items-center gap-1 cursor-pointer"
      key={item}
    >
      <p className=" text-sm">{item}</p>
      <input
        type="checkbox"
        name={item}
        checked={formClient.call_time.includes(item)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          dispatch(setCallTime({ ch: e.target.checked, val: item }))
        }
      />
    </label>
  ));

  const DeliveryTime = time.map((item: any) => (
    <label
      className=" flex flex-row-reverse justify-center items-center gap-1 cursor-pointer"
      key={item}
    >
      <p className=" text-sm">{item}</p>
      <input
        type="checkbox"
        name={item}
        checked={formClient.delivery_time.includes(item)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          dispatch(setDeliveryTime({ ch: e.target.checked, val: item }))
        }
      />
    </label>
  ));

  const streatDir = ["ذهاب", "ذهاب وعوده"].map((item: any) => (
    <label
      className=" flex flex-row-reverse justify-center items-center gap-2 cursor-pointer"
      key={item}
    >
      <p>{item}</p>
      <input
        type="radio"
        name="streatDir"
        checked={formClient.street_type === item}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          dispatch(setTypeStreet(item))
        }
      />
    </label>
  ));

  const hours24 = [
    { id: 0, name: "لأ" },
    { id: 1, name: "نعم" },
  ].map((item: any) => (
    <label
      className=" flex flex-row-reverse justify-center items-center gap-2 cursor-pointer"
      key={item.id}
    >
      <p>{item.name}</p>
      <input
        type="radio"
        name="hours24"
        checked={formClient.is_24_hours === item.id}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          dispatch(setIs24Hours(item.id))
        }
      />
    </label>
  ));

  return (
    <Form submit={submit} route="/dashboard/clients">
      <Input
        type="text"
        text="أسم التوقيع"
        placeholder="مطعم السباع"
        value={formClient.name}
        fun={setName}
        error={isError && formClient.name === ""}
      />
      <Input
        type="tel"
        text="الجوال"
        placeholder="05974132564"
        value={
          formClient.phone === "null"
            ? ""
            : formClient.phone === ""
            ? ""
            : formClient.phone
        }
        fun={setPhone}
        error={isError && formClient.phone === ""}
      />
      <Input
        type="tel"
        text="الجوال (إختياري)"
        placeholder="05974132564"
        value={
          formClient.phone2 === "null"
            ? ""
            : formClient.phone2 === ""
            ? ""
            : formClient.phone2
        }
        fun={setPhone2}
      />
      <Input
        type="text"
        text="إحداثيات الموقع علي الخريطة"
        placeholder="30.137360157025668, 31.38265565767019"
        fun={setGPS}
        error={
          isError && (formClient.latitude === "" || formClient.longitude === "")
        }
      />
      <Input
        type="text"
        text="وصف الموقع"
        placeholder="العزيزية"
        value={formClient.location_description}
        fun={setLocationDescription}
        error={isError && formClient.location_description === ""}
      />
      <Input
        type="number"
        text="عدد الوحدات"
        min={0}
        placeholder="0"
        value={formClient.number_of_units === 0 ? "" : formClient.number_of_units}
        fun={setNumberOfUnits}
        error={isError && formClient.number_of_units === 0}
      />
      <Select
        text="نوع الشارع"
        error={isError && formClient.street_type === ""}
      >
        {streatDir}
      </Select>
      <DropMenu
        type="radio"
        text="نوع النشاط"
        fun={setShowTypeWork}
        action={showTypeWork}
        error={isError && formClient.client_type_id === -1}
      >
        {DataWork}
      </DropMenu>
      <DropMenu
        type="radio"
        text="جنسية محاسب"
        fun={setShowTypeNationality}
        action={showTypeNationality}
        error={isError && formClient.accountant_nationality_id === -1}
      >
        {DataNationality}
      </DropMenu>
      <DropMenu
        type="radio"
        text="المنطقة"
        fun={setShowTypeArea}
        action={showTypeArea}
        error={isError && formClient.area_id === -1}
      >
        {DataArea}
      </DropMenu>
      <Input
        type="time"
        text="وقت الأفتتاح"
        placeholder="الوصف"
        value={formClient.opening_time}
        fun={setOpeningTime}
        error={isError && formClient.opening_time === ""}
      />
      <Input
        type="time"
        text="وقت الأغلاق"
        placeholder="عدد الوحدات"
        value={formClient.closing_time}
        fun={setClosingTime}
        error={isError && formClient.closing_time === ""}
      />
      <Select
        text="هل 24 ساعة؟"
        error={isError && formClient.is_24_hours === -1}
      >
        {hours24}
      </Select>
      <Select
        text="الوقت المناسب للاتصال"
        error={isError && formClient.call_time.length < 1}
      >
        {CallTime}
      </Select>
      <Select
        text="الوقت المناسب لتوصيل الطلب"
        error={isError && formClient.delivery_time.length < 1}
      >
        {DeliveryTime}
      </Select>
      <Input
        type="file"
        text="صورة التوقيع"
        placeholder="الوصف"
        value={undefined}
        fun={setSignaturePhoto}
        error={isError && formClient.signature_photo === ""}
      />
      <Input
        type="file"
        text="صورة المتجر"
        placeholder="عدد الوحدات"
        value={undefined}
        fun={setShopPhoto}
        error={isError && formClient.shop_photo === ""}
      />
      <Input
        type="submit"
        text={editForm ? "تعديل عميل" : "إضافة عميل"}
        loading={isLoading}
      />
    </Form>
  );
};

export default FormClient;

import React from "react";

// => props component
interface prop {
  fName: string;
}

const HelloMsg: React.FC<prop> = ({ fName }) => {
  return (
    <div className="flex flex-col whitespace-nowrap">
      <p className=" font-bold">مرحباً {fName},</p>
      <p>مرحباً بالعودة.</p>
    </div>
  );
};

export default HelloMsg;

import React from "react";
import Input from "../form/Input";

interface prop {
  submit: any;
  text: string;
  value: string;
  error?: boolean;
  children: any;
  fun?: any;
  loading?: boolean;
}

const NestedPage: React.FC<prop> = ({ submit, text, value, error, children, fun, loading }) => {

  return (
    <div className="grid grid-cols-12 gap-y-4 md:gap-x-8 md:gap-y-0">
      <div className="col-span-12 md:col-span-6">
        <form onSubmit={submit}>
          <Input type="text" text={text} error={error} fun={fun} />
          <Input type="submit" text={value} loading={loading} />
        </form>
      </div>
      <div className="col-span-12 md:col-span-6 flex flex-wrap gap-4 md:gap-y-0 py-4 px-4">
        {children}
      </div>
    </div>
  );
};

export default NestedPage;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";
import { useAppSelector } from "../../../hooks/store/useAppSelector";
import Swal from "sweetalert2";
import Button from "../button/Button";
import Table from "../table/Table";
import { setDefalt } from "../../../store/order/formSliceOrder";
import {
  deleteInvoice,
  filterOrderByStatus,
  getAllInvoice,
  getAllOrdersToday,
  nextPage,
  prevPage,
  searchOrderByNumberInvoice,
  selectOrderSearch,
  setSearchValue,
} from "../../../store/order/invoiceSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faBox,
  faPenToSquare,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import Search from "../form/Search";
import Filter from "../form/Filter";
import { setShowFilterInvoice } from "../../../store/main/mainSlice";

const ViewInvoice: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    invoices,
    isLoading,
    total,
    curent_page,
    number_page,
    searchOrderResult,
    searchValue,
    typeInvoice,
    statusInvoice
  } = useAppSelector((state) => state.order);

  const { showFilterInvoice } = useAppSelector((state) => state.mainSlice);

  useEffect(() => {
    if (searchValue === "") {
      dispatch(setDefalt(undefined));
      dispatch(getAllInvoice(1));
    } else {
      const time = setTimeout(() => {
        dispatch(searchOrderByNumberInvoice(searchValue));
      }, 1200);

      return () => clearTimeout(time);
    }
  }, [dispatch, searchValue]);

  useEffect(() => {
    if (curent_page === 1) {
      // dispatch(getAllInvoice(1));
    } else {
      const time = setTimeout(() => {
        if (typeInvoice === "filter" && statusInvoice !== -1) {
            dispatch(filterOrderByStatus({status: statusInvoice,numPage: curent_page}))
        }else {
          dispatch(getAllInvoice(curent_page));
        }
      }, 1200);

      return () => clearTimeout(time);
    }
  }, [dispatch, curent_page, typeInvoice, statusInvoice]);

  const statusOrder = [
    { id: 0, name: "تم رفع الطلب" },
    { id: 1, name: "تم اعتماد الطلب" },
    { id: 2, name: "يتم تحضير الطلب" },
    { id: 3, name: "الطلب مع السائق" },
    { id: 4, name: "تم تسليم الطلب" },
    { id: 5, name: "تم استلام المبلغ" },
    { id: 6, name: " كنسل الطلب" },
    { id: 7, name: "تم تقفيل الطلب" },
  ].map((item: any) => (
    <li
      className=" hover:text-blue hover:translate-x-[-4px] py-[1px] px-1 rounded-sm flex items-center gap-1"
      onClick={() => dispatch(filterOrderByStatus({status:item.id, numPage: 1}))}
      key={item.id}
    >
      <FontAwesomeIcon icon={faAnglesLeft} className=" text-xs" />
      <p className=" whitespace-nowrap">{item.name}</p>
    </li>
  ));

  const Row = invoices.map((item: any, index) => (
    <tr className=" border-b-2 solid border-eee hover:bg-eee" key={index}>
      <td className="p-2 text-lg text-center whitespace-nowrap">
        <FontAwesomeIcon icon={faBox} />
      </td>
      <td className="p-2 text-lg text-center whitespace-nowrap">
        <p>{item.invoice_number}</p>
      </td>
      <td className="p-2 text-lg text-center whitespace-nowrap">
        <p>{item.number_of_packages}</p>
      </td>
      <td className="p-2 text-lg text-center whitespace-nowrap">
        <p>{item.client_id?.name}</p>
      </td>
      <td className="p-2 text-lg text-center whitespace-nowrap">
        {item.driver_id.id === 1 ? "-------" : <p className=" whitespace-nowrap">{item.driver_id?.name}</p>}
      </td>
      <td className="p-2 text-sm md:text-lg text-center whitespace-nowrap">
        <div
          className={` ${
            item.status === 0
              ? " bg-[#ed7ca8]"
              : item.status === 1
              ? " bg-[#4C137E]"
              : item.status === 2
              ? " bg-blue"
              : item.status === 3
              ? " bg-yellow"
              : item.status === 4
              ? " bg-green"
              : item.status === 5
              ? " bg-[#ed7ca8]"
              : item.status === 6
              ? "bg-red"
              : "bg-[#52b9d8]"
          } h-5 rounded-lg flex justify-center items-center text-white px-1 text-sm`}
        >
          {item.status === 0
            ? "تم رفع الطلب"
            : item.status === 1
            ? "تم اعتماد الطلب"
            : item.status === 2
            ? "يتم تحضير الطلب"
            : item.status === 3
            ? "الطلب مع السائق"
            : item.status === 4
            ? "تم تسليم الطلب"
            : item.status === 5
            ? "تم استلام المبلغ"
            : item.status === 6
            ? "العميل كنسل الطلب"
            : "تم تقفيل الطلب"}
        </div>
      </td>
      <td className="p-2 text-lg text-center whitespace-nowrap">
        {item.payment_status === 0 ? (
          "-------"
        ) : (
          <p>
            {item.payment_status === 0
              ? "لم يتم الاستلام"
              : item.payment_status === 1
              ? "كاش"
              : item.payment_status === 2
              ? "شبكة"
              : item.payment_status === 3
              ? "تحويل"
              : "آجل"}
          </p>
        )}
      </td>
      <td className="p-2 text-lg text-center">
        <div className=" flex gap-4 justify-center">
          <Link to="form-invoice" className=" flex justify-center items-center">
            <FontAwesomeIcon
              icon={faPenToSquare}
              className=" cursor-pointer text-green"
              onClick={() => dispatch(setDefalt(item))}
            />
          </Link>
          <FontAwesomeIcon
            icon={faTrashCan}
            className=" cursor-pointer text-red"
            onClick={() =>
              Swal.fire({
                title: " هل أنت متأكد!",
                text: "في حالة الحذف لن تتمكن من العودة إلي هذا.",
                imageUrl: "/trash.svg",
                imageWidth: 100,
                showCancelButton: true,
                confirmButtonColor: "#D9003F",
                cancelButtonColor: "#4A5CB6",
                confirmButtonText: "نعم",
                cancelButtonText: "لا",
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(deleteInvoice(item.id));
                }
              })
            }
          />
        </div>
      </td>
    </tr>
  ));

  return (
    <>
      <div className=" absolute top-0 left-11 md:left-4 z-30 text-sm">
        <Search
          height=" h-8"
          top="top-[33px]"
          error={false}
          placeholder={"ابحث برقم الطلب"}
          fun={setSearchValue}
          data={searchOrderResult}
          nestFun={selectOrderSearch}
          clientName={searchValue}
        />
      </div>
      <div className=" h-9">
        <div className=" flex justify-between items-end">
          <Button
            icon={<FontAwesomeIcon icon={faPlus} />}
            text={"طلب"}
            route={"form-invoice"}
            fun={setDefalt(undefined)}
          />
          <div className=" flex gap-2">
            <Filter
              text="حالة الطلب"
              fun={setShowFilterInvoice}
              action={showFilterInvoice}
            >
              <>
                <li
                  className=" hover:text-blue hover:translate-x-[-4px] py-[1px] px-1 rounded-sm flex items-center gap-1"
                  onClick={() => dispatch(getAllInvoice(curent_page))}
                >
                  <FontAwesomeIcon icon={faAnglesLeft} className=" text-xs" />
                  <p>كل الطلبات</p>
                </li>
                {statusOrder}
              </>
            </Filter>
          </div>
        </div>
      </div>
      <Table
        tHead={[
          <FontAwesomeIcon icon={faBox} />,
          "رقم الطلب",
          "عدد الطرود",
          "أسم العميل",
          "أسم السائق",
          "الحالة",
          "الفلوس",
          "التعديل & الحذف",
        ]}
        total={total}
        curent_page={curent_page}
        number_page={number_page}
        nextPage={nextPage}
        prevPage={prevPage}
        loading={isLoading}
      >
        {Row}
      </Table>
    </>
  );
};

export default ViewInvoice;

import React from "react";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";
import Swal from "sweetalert2";

// => props component
interface prop {
  text: string;
  id: number;
  fun: any;
}

const Tages: React.FC<prop> = ({ text, id, fun }) => {
  const dispatch = useAppDispatch();

  return (
    <div className=" relative py-2 px-3 h-fit rounded-sm rounded-bl-lg bg-eee">
      <p
        className=" absolute w-6 h-6 rounded-full flex justify-center items-center top-[-10px] right-[-10px] cursor-pointer bg-red text-white"
        onClick={() => {
          Swal.fire({
            text: "في حالة الحذف هيتم حذفها من اي عميل يمتلك هذا",
            title: " هل أنت متأكد!",
            imageUrl: "/trash.svg",
            imageWidth: 100,
            showCancelButton: true,
            confirmButtonColor: "#D9003F",
            cancelButtonColor: "#4A5CB6",
            confirmButtonText: "نعم",
            cancelButtonText: "لا",
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(fun(id));
            }
          });
        }}
      >
        x
      </p>
      <p>{text}</p>
    </div>
  );
};

export default Tages;

import { createSlice } from "@reduxjs/toolkit";

/*
  ===================
  ====== Slice ======
  ===================
*/

type initState = {
  formNationality: { accountant_nationality: string };
  isError: boolean;
};

const initialState: initState = {
  formNationality: {
    accountant_nationality: "",
  },
  isError: false,
};

const fromSliceNationality = createSlice({
  name: "formNationality",
  initialState: initialState,
  reducers: {
    // Set Date.
    setFormNationality: (state, action) => {
      state.formNationality.accountant_nationality = action.payload;
    },

    // Set Error.
    setIsError: (state) => {
      state.isError = true;
    },

    // Set Default.
    setDefault: (state, action) => {
      if (action.payload === undefined) {
        state.formNationality.accountant_nationality = "";
        state.isError = false;
      }
    },
  },
});

export default fromSliceNationality.reducer;
export const { setFormNationality, setIsError, setDefault } =
  fromSliceNationality.actions;

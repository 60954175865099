import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";

const Pagination: React.FC<any> = ({
  total,
  currentPage,
  numPage,
  nextFun,
  prevFun,
}) => {
  const dispatch = useAppDispatch();
  return (
    <div className=" flex justify-center items-center gap-7 text-lg">
      <p>
        Total: <span> {total} </span>
      </p>
      <div className=" flex items-center gap-4">
        <FontAwesomeIcon
          icon={faAnglesRight}
          className=" cursor-pointer hover:text-[#4a5cb6]"
          onClick={() => dispatch(nextFun())}
        />
        <div>
          {numPage}
          <span> / </span>
          {currentPage}
        </div>
        <FontAwesomeIcon
          icon={faAnglesLeft}
          className=" cursor-pointer hover:text-[#4a5cb6]"
          onClick={() => dispatch(prevFun())}
        />
      </div>
    </div>
  );
};

export default Pagination;

import React from "react";
import HelloMsg from "../../components/dashboard/admin/HelloMsg";
import Date from "../../components/dashboard/admin/Date";
import TotalData from "../../components/dashboard/admin/main/TotalData";
import MapGps from "../../components/dashboard/admin/main/MapGps";
import Cookie from "cookie-universal"
import MapAdmin from "../../components/dashboard/admin/main/MapAdmin";
import { useAppSelector } from "../../hooks/store/useAppSelector";

const Dashboard: React.FC = () => {
  const cookie = Cookie();
  const { showSwapMap } = useAppSelector(state => state.mainSlice);
  return (
    <>
      <div className=" w-full flex justify-between items-center gap-x-2">
        <HelloMsg fName={cookie.get('name_user')} />
        <Date />
      </div>
      <div>
        <div className=" mb-2">
          <h2 className="lg:text-lg font-bold">لوحة التحكم</h2>
        </div>
        <div className=" flex flex-col gap-y-4 h-vh-minus-72 overflow-x-hidden overflow-y-scroll pt-1 pb-20">
          <TotalData />
          {
            cookie.get("permission") === "Admin" ? <MapGps /> : (showSwapMap ? <MapGps /> : <MapAdmin />) 
          }
        </div>
      </div>
    </>
  );
};

export default Dashboard;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { End_NATIONALITY, URL_BASEURL } from "../../../api/api";
import { nationalityState } from "./interfaceNationalitySlice";
import axios from "axios";
// => Notification
import { useMsgSuccess } from "../../../hooks/toastify/notification";
// => Cookie
import Cookie from "cookie-universal";
const cookie = Cookie();

/*
  ==============================
  ====== CreateAsyncThunk ======
  ==============================
*/

// [1] => Get All Nationalities.
export const getAllNationality = createAsyncThunk(
  "nationality/getAll",
  async (_, thunkAPI) => {
    try {
      const res = await axios
        .get(`${URL_BASEURL}${End_NATIONALITY}`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [2] => Create Nationality.
export const createNationality = createAsyncThunk(
  "nationality/create",
  async (nationalityData: { accountant_nationality: string }, thunkAPI) => {
    try {
      const res = await axios
        .post(`${URL_BASEURL}${End_NATIONALITY}`, nationalityData, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [3] => Delete Nationality.
export const deleteNationality = createAsyncThunk(
  "nationality/delete",
  async (nationalityId: number, thunkAPI) => {
    try {
      await axios.delete(`${URL_BASEURL}${End_NATIONALITY}/${nationalityId}`, {
        headers: {
          Authorization: "bearer " + cookie.get("access_token"),
        },
      });

      return nationalityId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/*
  ===================
  ====== Slice ======
  ===================
*/

const initialState: nationalityState = {
  nationalities: [],
  isLoading: false,
};

const nationalitySlice = createSlice({
  name: "nationality",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // [1] => Get All Nationalities.
    builder.addCase(getAllNationality.pending, (state, action) => {});
    builder.addCase(getAllNationality.fulfilled, (state, action) => {
      if (action.payload.successful) {
        state.nationalities = action.payload.data.data;
      }
    });
    builder.addCase(getAllNationality.rejected, (state, action) => {});

    // [2] => Create Nationality.
    builder.addCase(createNationality.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createNationality.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.successful) {
        state.nationalities.push(action.payload.data);
        useMsgSuccess(action.payload.message);
      }
    });
    builder.addCase(createNationality.rejected, (state, action) => {
      state.isLoading = false;
    });

    // [3] => Delete Nationality.
    builder.addCase(deleteNationality.pending, (state, action) => {});
    builder.addCase(deleteNationality.fulfilled, (state, action) => {
      state.nationalities = state.nationalities.filter(
        (item) => item.id !== action.payload
      );
      useMsgSuccess("تم الحذف الجنسية بنجاح");
    });
    builder.addCase(deleteNationality.rejected, (state, action) => {});
  },
});

export default nationalitySlice.reducer;

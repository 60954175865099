import { createSlice } from "@reduxjs/toolkit";
import { objectclient } from "./interfaceClientSlice";

/*
  ===================
  ====== Slice ======
  ===================
*/

type initState = {
  formClient: objectclient;
  isError: boolean;
  clientName: string;
  editForm: boolean;
};

const initialState: initState = {
  formClient: {
    name: "",
    phone: "",
    phone2: "",
    number_of_units: 0,
    client_type_id: -1,
    accountant_nationality_id: -1,
    area_id: -1,
    latitude: "",
    longitude: "",
    opening_time: "",
    closing_time: "",
    is_24_hours: -1,
    location_description: "",
    street_type: "",
    call_time: [],
    delivery_time: [],
    shop_photo: null,
    signature_photo: null,
  },
  isError: false,
  editForm: false,
  clientName: "",
};

const formSliceClient = createSlice({
  name: "formClient",
  initialState: initialState,
  reducers: {
    // Set Date
    setName: (state, action) => {
      state.formClient.name = action.payload;
    },
    setPhone: (state, action) => {
      state.formClient.phone = action.payload;
    },
    setPhone2: (state, action) => {
      state.formClient.phone2 = action.payload;
    },
    setNumberOfUnits: (state, action) => {
      state.formClient.number_of_units = action.payload;
    },
    setTypeWorkId: (state, action) => {
      state.formClient.client_type_id = action.payload;
    },
    setTypeNationalit: (state, action) => {
      state.formClient.accountant_nationality_id = action.payload;
    },
    setTypeArea: (state, action) => {
      state.formClient.area_id = action.payload;
    },
    setGPS: (state, action) => {
      const data = action.payload.split(",");
      const lat = data[0];
      const lng = data[1];
      state.formClient.latitude = lat;
      state.formClient.longitude = lng;
    },
    setOpeningTime: (state, action) => {
      state.formClient.opening_time = action.payload;
    },
    setClosingTime: (state, action) => {
      state.formClient.closing_time = action.payload;
    },
    setIs24Hours: (state, action) => {
      state.formClient.is_24_hours = action.payload;
    },
    setLocationDescription: (state, action) => {
      state.formClient.location_description = action.payload;
    },
    setTypeStreet: (state, action) => {
      state.formClient.street_type = action.payload;
    },
    setCallTime: (state, action) => {
      action.payload.ch
        ? state.formClient.call_time.push(action.payload.val)
        : (state.formClient.call_time = state.formClient.call_time.filter(
            (item) => item !== action.payload.val
          ));
    },
    setDeliveryTime: (state, action) => {
      action.payload.ch
        ? state.formClient.delivery_time.push(action.payload.val)
        : (state.formClient.delivery_time =
            state.formClient.delivery_time.filter(
              (item) => item !== action.payload.val
            ));
    },
    setShopPhoto: (state, action) => {
      state.formClient.shop_photo = action.payload;
    },
    setSignaturePhoto: (state, action) => {
      state.formClient.signature_photo = action.payload;
    },
    setClientName: (state, action) => {
      state.clientName = action.payload;
    },

    // set Error
    setisError: (state) => {
      state.isError = true;
    },

    // set prev Data
    setDefault: (state, action) => {
      state.isError = false;
      if (action.payload === undefined) {
        state.editForm = false;
        state.formClient.name = "";
        state.formClient.phone = "";
        state.formClient.phone2 = "";
        state.formClient.number_of_units = 0;
        state.formClient.client_type_id = -1;
        state.formClient.accountant_nationality_id = -1;
        state.formClient.area_id = -1;
        state.formClient.latitude = "";
        state.formClient.longitude = "";
        state.formClient.opening_time = "";
        state.formClient.closing_time = "";
        state.formClient.is_24_hours = -1;
        state.formClient.location_description = "";
        state.formClient.street_type = "";
        state.formClient.call_time = [];
        state.formClient.delivery_time = [];
        state.formClient.shop_photo = "";
        state.formClient.signature_photo = "";
      } else {
        state.editForm = true;
        state.formClient.name = "";
        state.formClient.phone = "";
        state.formClient.phone2 = "";
        state.formClient.number_of_units = 0;
        state.formClient.client_type_id = 0;
        state.formClient.accountant_nationality_id = -1;
        state.formClient.area_id = -1;
        state.formClient.latitude = "";
        state.formClient.longitude = "";
        state.formClient.opening_time = "";
        state.formClient.closing_time = "";
        state.formClient.is_24_hours = -1;
        state.formClient.location_description = "";
        state.formClient.street_type = "";
        state.formClient.call_time = [];
        state.formClient.delivery_time = [];
        state.formClient.shop_photo = "";
        state.formClient.signature_photo = "";

        state.formClient.id = action.payload.id;
        state.formClient.name = action.payload.name;
        state.formClient.phone = action.payload.phone;
        state.formClient.phone2 = action.payload.phone2;
        state.formClient.number_of_units = action.payload.number_of_units;
        state.formClient.client_type_id = action.payload.client_type_id.id;
        state.formClient.accountant_nationality_id =
          action.payload.accountant_nationality_id.id;
        state.formClient.area_id = action.payload.area_id.id;
        state.formClient.latitude = action.payload.latitude;
        state.formClient.longitude = action.payload.longitude;
        state.formClient.opening_time = action.payload.opening_time
          .split(":")
          .filter((el: any, index: number) => index !== 2)
          .join(":");
        state.formClient.closing_time = action.payload.closing_time
          .split(":")
          .filter((el: any, index: number) => index !== 2)
          .join(":");
        state.formClient.is_24_hours = action.payload.is_24_hours;
        state.formClient.location_description =
          action.payload.location_description;
        state.formClient.street_type = action.payload.street_type;
        state.formClient.call_time = action.payload.call_time;
        state.formClient.delivery_time = action.payload.delivery_time;
        state.formClient.shop_photo = action.payload.shop_photo;
        state.formClient.signature_photo = action.payload.signature_photo;
      }
    },
  },
  extraReducers: {},
});

export default formSliceClient.reducer;
export const {
  setDefault,
  setName,
  setPhone,
  setPhone2,
  setNumberOfUnits,
  setTypeWorkId,
  setTypeNationalit,
  setTypeArea,
  setGPS,
  setOpeningTime,
  setClosingTime,
  setIs24Hours,
  setLocationDescription,
  setTypeStreet,
  setCallTime,
  setDeliveryTime,
  setShopPhoto,
  setSignaturePhoto,
  setisError,
  setClientName,
} = formSliceClient.actions;

import React from "react";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";
import { Link } from "react-router-dom";

// => props component
interface prop {
  icon: any;
  text: string;
  route: string;
  fun: any;
}

const Button: React.FC<prop> = ({ icon, text, route, fun }) => {
  const dispatch = useAppDispatch();
  return (
    <Link
      to={route}
      className=" p-1 w-fit flex items-center gap-2 text-lg bg-transparent border-blue border-b-2 border-solid hover:bg-blue hover:text-white transition-all duration-150"
      onClick={() => dispatch(fun)}
    >
      {icon}
      <p>{text}</p>
    </Link>
  );
};

export default Button;

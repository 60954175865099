import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/store/useAppDispatch";
import { useAppSelector } from "../../hooks/store/useAppSelector";
import Input from "../dashboard/form/Input";
import {
  setEmail,
  setIsError,
  setPassword,
} from "../../store/auth/formSliceAuth";
import { login } from "../../store/auth/authSlice";

const FormLogin: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formAuth, isError } = useAppSelector((state) => state.formAuth);
  const { isLoading } = useAppSelector((state) => state.auth);

  const submit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      formAuth.email === "" ||
      formAuth.password === "" ||
      formAuth.password.length < 8
    ) {
      dispatch(setIsError());
    } else {
      dispatch(login(formAuth)).then((res) => {
        if (res.payload.status) {
          if (res.payload.roles_name[0].name !== "Driver") {
            navigate("/dashboard", { replace: true });
          }
        }
      });
    }
  };

  return (
    <>
      <div className=" flex flex-col gap-y-2">
        <p className=" text-2xl font-bold">مرحباً بالعودة</p>
        <p className=" text-md">مرحباً بالعودة! من فضلك أدخل البيانات.</p>
      </div>
      <div>
        <form className=" flex flex-col gap-y-2" onSubmit={submit}>
          <Input
            type="email"
            text="الإيميل"
            placeholder="أدخل إيميلك"
            fun={setEmail}
            error={isError && formAuth.email === "" ? true : false}
          />
          <Input
            type="password"
            text="الرقم السري"
            placeholder="********"
            fun={setPassword}
            error={
              isError &&
              (formAuth.password === "" || formAuth.password.length < 8)
                ? true
                : false
            }
          />
          <Input type="submit" text="سجل الدخول" loading={isLoading} />
        </form>
      </div>
    </>
  );
};

export default FormLogin;

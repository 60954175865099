import { createSlice } from "@reduxjs/toolkit";
import { objectAuth } from "./interfaceAuth";

/*
  ===================
  ====== Slice ======
  ===================
*/

type initState = {
  formAuth: objectAuth;
  isError: boolean;
};

const initialState: initState = {
  formAuth: {
    email: "",
    password: "",
  },
  isError: false,
};

const formSliceAuth = createSlice({
  name: "formAuth",
  initialState,
  reducers: {
    // Set Data.
    setEmail: (state, action) => {
      state.formAuth.email = action.payload;
    },
    setPassword: (state, action) => {
      state.formAuth.password = action.payload;
    },

    // Set Error.
    setIsError: (state) => {
      state.isError = true;
    },
  },
});

export default formSliceAuth.reducer;
export const { setEmail, setPassword, setIsError } = formSliceAuth.actions;

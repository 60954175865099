import React from "react";
import { Link } from "react-router-dom";

const Landing: React.FC = () => {
  return (
    <div className=" h-screen w-screen flex flex-col gap-y-3 justify-center items-center text-sm md:text-2xl font-Cairo">
      <h1>اهلاً بكم في رمز العقود</h1>
      <h2>قريباً سيتم إطلاق المنصة</h2>
      <Link
        to="/dashboard"
        className=" bg-blue hover:opacity-70 transition-all duration-100 text-lg py-2 px-6 rounded-md text-white cursor-pointer"
      >
        لوحة التحكم
      </Link>
    </div>
  );
};

export default Landing;

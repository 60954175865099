import React, { useEffect } from "react";
import { useAppSelector } from "../../../hooks/store/useAppSelector";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { End_IMAGE, URL_BASEURL } from "../../../api/api";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../button/Button";
import Table from "../table/Table";
import Filter from "../form/Filter";
import Search from "../form/Search";
import {
  faAnglesLeft,
  faBagShopping,
  faMapLocation,
  faPenToSquare,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { setDefault } from "../../../store/clients/main/formSliceClient";
import {
  deleteClient,
  getAllClients,
  nextPage,
  prevPage,
  selectClientSearch,
  setSearchValue,
} from "../../../store/clients/main/clientSlice";
import {
  filterClientByWork,
  getAllWork,
} from "../../../store/clients/work/workSlice";
import {
  setShowFilterArea,
  setShowFilterWork,
} from "../../../store/main/mainSlice";
import {
  filterClientByArea,
  getAllArea,
} from "../../../store/clients/area/areaSlice";
import {
  searchClientByName,
  setDefalt,
} from "../../../store/order/formSliceOrder";

const ViewClients: React.FC = () => {
  const dispatch = useAppDispatch();

  const { clients, curent_page, total, number_page, searchValue } =
    useAppSelector((state) => state.clients);
  const { showFilterWork, showFilterArea } = useAppSelector(
    (state) => state.mainSlice
  );
  const { works } = useAppSelector((state) => state.work);
  const { areas } = useAppSelector((state) => state.areas);
  const { clientsResult } = useAppSelector((state) => state.formOrder);

  // show Location in Swal
  const handlerGps = (lat: string, long: string) => {
    const mapUrl = `https://maps.google.com/maps?q=${lat},${long}&hl=ar&z=14&amp;output=embed`;
    Swal.fire({
      title: "خريطة الموقع",
      html: `<iframe width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src=${mapUrl}></iframe>`,
      showCloseButton: true,
      showConfirmButton: false,
    });
  };

  // show Image in Swal
  const handlerImg = (urlImg: string, title: string) => {
    Swal.fire({
      title: `${title}`,
      imageUrl: `${urlImg}`,
      imageWidth: "calc(100% - 10px)",
      imageHeight: 200,
      showCloseButton: true,
      showConfirmButton: false,
    });
  };

  // convert Time from 24H --> 12H
  const convertTime = (time: any) => {
    const arrayTime = time.split(":");
    const hour = arrayTime[0];
    const minute = arrayTime[1];
    let convertedTime = "";
    let period = "";

    if (hour > 12) {
      convertedTime = `${hour - 12}:${minute}`;
      period = "م";
    } else if (hour === "00") {
      convertedTime = `12:${minute}`;
      period = "ص";
    } else {
      convertedTime = `${hour}:${minute}`;
      period = "ص";
    }

    return `${convertedTime} ${period}`;
  };

  useEffect(() => {
    if (curent_page === 1) {
      dispatch(getAllClients(1));
    } else {
      const time = setTimeout(() => {
        dispatch(getAllClients(curent_page));
      }, 1200);

      return () => clearTimeout(time);
    }
  }, [dispatch, curent_page]);

  useEffect(() => {
    if (searchValue === "") {
      dispatch(setDefalt(undefined));
      dispatch(getAllClients(1));
    } else {
      const time = setTimeout(() => {
        dispatch(searchClientByName(searchValue));
      }, 1200);

      return () => clearTimeout(time);
    }
  }, [dispatch, searchValue]);

  useEffect(() => {
    dispatch(getAllArea());
    dispatch(getAllWork());
  }, [dispatch]);

  // row table body
  const Row = clients.map((item) => (
    <tr
      className=" border-b-2 solid border-eee hover:bg-eee transition-all duration-100"
      key={item.id}
    >
      <td className="p-2 text-sm text-center">
        <FontAwesomeIcon icon={faBagShopping} />
      </td>
      <td className="py-2 px-4 text-sm text-center">
        <div className=" flex flex-col gap-1">
          <div className=" flex gap-1">
            <p className=" font-bold whitespace-nowrap">الإسم: </p>
            <p className=" whitespace-nowrap"> {item.name} </p>
          </div>
          <div className=" flex gap-1">
            <p className=" font-bold whitespace-nowrap">الجوال: </p>
            <p className=" whitespace-nowrap">{item.phone}</p>
          </div>
          {item.phone2 !== "null" && (
            <div className=" flex gap-1">
              <p className=" font-bold whitespace-nowrap">الجوال: </p>
              <p className=" whitespace-nowrap"> {item.phone2} </p>
            </div>
          )}
        </div>
      </td>
      <td className="py-2 px-4 text-sm text-center">
        <div className=" flex flex-col gap-1">
          <div className=" flex gap-1">
            <p className=" font-bold whitespace-nowrap">المنطقة: </p>
            <div className=" flex items-center gap-2">
              <p className="whitespace-nowrap">{item.area_id.name}</p>
              <FontAwesomeIcon
                icon={faMapLocation}
                className=" cursor-pointer hover:text-blue"
                onClick={() => handlerGps(item.latitude, item.longitude)}
              />
            </div>
          </div>
          <div className=" flex gap-1">
            <p className=" font-bold whitespace-nowrap">نوع الشارع: </p>
            <p className="whitespace-nowrap"> {item.street_type} </p>
          </div>
          <div className=" flex gap-1">
            <p className=" font-bold whitespace-nowrap">وصف الموقع: </p>
            <p className="whitespace-nowrap"> {item.location_description} </p>
          </div>
          <div className=" flex gap-1">
            <p className=" font-bold whitespace-nowrap">عدد الوحدات: </p>
            <p className="whitespace-nowrap"> {item.number_of_units} </p>
          </div>
        </div>
      </td>
      <td className="py-2 px-4 text-sm text-center">
        <div className=" flex flex-col gap-1">
          <div className=" flex gap-1">
            <p className=" font-bold whitespace-nowrap">نوع العمل: </p>
            <p className="whitespace-nowrap"> {item.client_type_id.name} </p>
          </div>
          <div className=" flex gap-1">
            <p className=" font-bold whitespace-nowrap">جنسية المحاسب: </p>
            <p className="whitespace-nowrap">
              {item.accountant_nationality_id.name}
            </p>
          </div>
        </div>
      </td>
      <td className="py-2 px-4 text-sm text-center">
        <div className=" flex flex-col gap-1 relative">
          <div className=" flex gap-1">
            <p className=" font-bold whitespace-nowrap">وقت الافتتاح: </p>
            <p className="whitespace-nowrap">
              {convertTime(item.opening_time)}
            </p>
          </div>
          <div className=" flex gap-1">
            <p className=" font-bold whitespace-nowrap">وقت لاغلاق: </p>
            <p className="whitespace-nowrap">
              {convertTime(item.closing_time)}
            </p>
          </div>
          <div className=" flex gap-1">
            <p className=" font-bold whitespace-nowrap">وقت الاتصال: </p>
            <p className="whitespace-nowrap"> {item.call_time.join(" | ")} </p>
          </div>
          <div className=" flex gap-1">
            <p className=" font-bold whitespace-nowrap">وقت التوصيل: </p>
            <p className="whitespace-nowrap">
              {item.delivery_time.join(" | ")}
            </p>
          </div>
          {item.is_24_hours === 1 && (
            <div className=" absolute top-8 left-[-38px] rotate-90 bg-[#de7d39] text-white py-[1px] px-[2px] rounded-lg text-[12px]">
              <p className="whitespace-nowrap">24 ساعة</p>
            </div>
          )}
        </div>
      </td>
      <td className="py-2 px-4 text-sm text-center text-">
        <div className=" flex flex-col gap-1">
          <div className=" flex gap-1 items-center">
            <p className=" font-bold">صوره التوقيع: </p>
            <div className=" w-16 h-10 rounded-md overflow-hidden cursor-pointer">
              <img
                className=" w-full h-full"
                src={`${URL_BASEURL}/${End_IMAGE}/signature_photo/${item.signature_photo}`}
                alt=""
                onClick={() =>
                  handlerImg(
                    `${URL_BASEURL}/${End_IMAGE}/signature_photo/${item.signature_photo}`,
                    "صورة التوقيع"
                  )
                }
              />
            </div>
          </div>
          <div className=" flex gap-1 items-center">
            <p className=" font-bold">صوره المتجر: </p>
            <div className=" w-16 h-10 rounded-md overflow-hidden cursor-pointer">
              <img
                className=" w-full h-full"
                src={`${URL_BASEURL}/${End_IMAGE}/shop_photo/${item.shop_photo}`}
                alt=""
                onClick={() =>
                  handlerImg(
                    `${URL_BASEURL}/${End_IMAGE}/shop_photo/${item.shop_photo}`,
                    "صورة المتجر"
                  )
                }
              />
            </div>
          </div>
        </div>
      </td>
      <td className="py-2 px-4 text-sm text-center">
        <div className=" flex gap-4 justify-center text-lg">
          <Link to="form-client" className=" flex justify-center items-center">
            <FontAwesomeIcon
              icon={faPenToSquare}
              className=" cursor-pointer text-[#00BE10]"
              onClick={() => dispatch(setDefault(item))}
            />
          </Link>
          <FontAwesomeIcon
            icon={faTrashCan}
            className=" cursor-pointer text-red"
            onClick={() =>
              Swal.fire({
                title: " هل أنت متأكد!",
                text: "في حالة الحذف لن تتمكن من العودة إلي هذا.",
                imageUrl: "/trash.svg",
                imageWidth: 100,
                showCancelButton: true,
                confirmButtonColor: "#D9003F",
                cancelButtonColor: "#4A5CB6",
                confirmButtonText: "نعم",
                cancelButtonText: "لا",
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(deleteClient(item.id));
                }
              })
            }
          />
        </div>
      </td>
    </tr>
  ));

  const RowWork = works.map((item: any) => (
    <li
      className=" hover:text-blue hover:translate-x-[-4px] py-[1px] px-1 rounded-sm flex items-center gap-1"
      onClick={() => dispatch(filterClientByWork(item.id))}
      key={item.id}
    >
      <FontAwesomeIcon icon={faAnglesLeft} className=" text-xs" />
      <p>{item.client_type}</p>
    </li>
  ));

  const RowArea = areas.map((item: any) => (
    <li
      className=" hover:text-blue hover:translate-x-[-4px] py-[1px] px-1 rounded-sm flex items-center gap-1"
      onClick={() => dispatch(filterClientByArea(item.id))}
      key={item.id}
    >
      <FontAwesomeIcon icon={faAnglesLeft} className=" text-xs" />
      <p>{item.area_name}</p>
    </li>
  ));

  return (
    <>
      <div className=" absolute top-0 left-11 md:left-4 z-30 text-sm">
        <Search
          height=" h-8"
          top="top-[33px]"
          error={false}
          placeholder={"ابحث بأسم العميل"}
          fun={setSearchValue}
          data={clientsResult}
          nestFun={selectClientSearch}
          clientName={searchValue}
        />
      </div>
      <div className=" h-9">
        <div className=" flex justify-between items-end">
          <Button
            icon={<FontAwesomeIcon icon={faPlus} />}
            text={"عميل"}
            route={"form-client"}
            fun={setDefault(undefined)}
          />
          <div className=" flex gap-2">
            <Filter
              text="المنطقة"
              fun={setShowFilterArea}
              action={showFilterArea}
            >
              <>
                <li
                  className=" hover:text-blue hover:translate-x-[-4px] py-[1px] px-1 rounded-sm flex items-center gap-1"
                  onClick={() => dispatch(getAllClients(curent_page))}
                >
                  <FontAwesomeIcon icon={faAnglesLeft} className=" text-xs" />
                  <p>كل العملاء</p>
                </li>
                {RowArea}
              </>
            </Filter>
            <Filter
              text="نوع النشاط"
              fun={setShowFilterWork}
              action={showFilterWork}
            >
              <>
                <li
                  className=" hover:text-blue hover:translate-x-[-4px] py-[1px] px-1 rounded-sm flex items-center gap-1"
                  onClick={() => dispatch(getAllClients(curent_page))}
                >
                  <FontAwesomeIcon icon={faAnglesLeft} className=" text-xs" />
                  <p>كل العملاء</p>
                </li>
                {RowWork}
              </>
            </Filter>
          </div>
        </div>
      </div>
      <Table
        tHead={[
          <FontAwesomeIcon icon={faBagShopping} />,
          "بيانات العميل",
          "تفاصيل الموقع",
          "تفاصيل اخري",
          "التوقيت",
          "الصور",
          "التعديل & الحذف",
        ]}
        total={total}
        curent_page={curent_page}
        number_page={number_page}
        nextPage={nextPage}
        prevPage={prevPage}
        loading={true}
      >
        {Row}
      </Table>
    </>
  );
};

export default ViewClients;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

/*
  ===================
  ====== Slice ======
  ===================
*/

type initState = {
  formUser: any;
  isError: boolean;
  editForm: boolean;
};
const initialState: initState = {
  formUser: {
    name: "",
    email: "",
    password: "",
    roles_name: [],
  },
  isError: false,
  editForm: false,
};

// slice for user
const formSliceUser = createSlice({
  name: "formUser",
  initialState,
  reducers: {
    // add user data
    setName: (state, action: PayloadAction<string>): void => {
      state.formUser.name = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>): void => {
      state.formUser.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>): void => {
      state.formUser.password = action.payload;
    },
    setRoles: (state, action): void => {
      state.formUser.roles_name = [action.payload];
    },

    // set error
    setIsError: (state) => {
      state.isError = true;
    },

    // set prev data
    setDefault: (state, action) => {
      state.isError = false;
      if (action.payload === undefined) {
        state.editForm = false;
        state.formUser.id = undefined;
        state.formUser.name = "";
        state.formUser.email = "";
        state.formUser.password = "";
        state.formUser.roles_name = [];
      } else {
        state.editForm = true;
        state.formUser.name = "";
        state.formUser.email = "";
        state.formUser.password = "";
        state.formUser.roles_name = [];
        state.formUser.id = action.payload.id;
        state.formUser.name = action.payload.name;
        state.formUser.email = action.payload.email;
        state.formUser.roles_name = action.payload.roles_name;
      }
    },
  },
});

export default formSliceUser.reducer;
export const {
  setName,
  setEmail,
  setPassword,
  setRoles,
  setDefault,
  setIsError,
} = formSliceUser.actions;

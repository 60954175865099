import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { objectUser, userState } from "./interfaceUserSlice";
import { End_USERS, URL_BASEURL } from "../../../api/api";
// => Notification
import { useMsgError, useMsgSuccess } from "../../../hooks/toastify/notification";
// => Cookie
import Cookie from "cookie-universal";
const cookie = Cookie();

/*
  ==============================
  ====== CreateAsyncThunk ======
  ==============================
*/

// [1] => Get All Users.
export const getAllUsers = createAsyncThunk(
  "user/getAll",
  async (numPage: number, thunkAPI) => {
    try {
      const res = await axios
        .get(`${URL_BASEURL}${End_USERS}?page=${numPage}`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [2] => Create User.
export const createUser = createAsyncThunk(
  "user/create",
  async (userData: objectUser, thunkAPI) => {
    try {
      const res = await axios
        .post(`${URL_BASEURL}${End_USERS}`, userData, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [3] => Update User.
export const updateUser = createAsyncThunk(
  "user/update",
  async (userData: objectUser, thunkAPI) => {
    try {
      const res = await axios
        .put(`${URL_BASEURL}${End_USERS}/${userData.id}`, userData, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [4] => Delete User.
export const deleteUser = createAsyncThunk(
  "user/delete",
  async (userId: number, thunkAPI) => {
    try {
      await axios.delete(`${URL_BASEURL}${End_USERS}/${userId}`, {
        headers: {
          Authorization: "bearer " + cookie.get("access_token"),
        },
      });

      return userId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/*
  ===================
  ====== Slice ======
  ===================
*/

const initialState: userState = {
  isLoading: false,
  users: [],
  total: 0,
  curent_page: 1,
  number_page: 0,
};
const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    // Set Data
    nextPage: (state): void => {
      if (state.curent_page < state.number_page) {
        state.curent_page = state.curent_page + 1;
      }
    },
    prevPage: (state): void => {
      if (state.curent_page > 1) {
        state.curent_page = state.curent_page - 1;
      }
    },
  },
  extraReducers: (builder) => {
    // [1] => Get All Users.
    builder.addCase(getAllUsers.pending, (state, action): void => {});
    builder.addCase(getAllUsers.fulfilled, (state, action): void => {
      state.users = action.payload.data;
      state.total = action.payload.meta.total;
      state.number_page = action.payload.meta.last_page;
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {});

    // [2] => Create User.
    builder.addCase(createUser.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(createUser.fulfilled, (state, action): void => {
      state.isLoading = false;
      if (action.payload.status) {
        state.users.push(action.payload.data);
        state.total = state.total + 1;
        useMsgSuccess("تم الإضافة بنجاح");
      }
      if (action.payload.errors?.email) {
        useMsgError('الإيميل مستخدم من قبل');
      }
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.isLoading = false;
    });

    // [3] => Update User.
    builder.addCase(updateUser.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action): void => {
      state.isLoading = false;
      if (action.payload.status) {
        state.users = state.users.filter(
          (item) => item.id !== action.payload.data.id
        );
        state.users.push(action.payload.data);
        useMsgSuccess("تم التعديل بنجاح");
      }else {
        useMsgError('الإيميل مستخدم من قبل');
      }
    });
    builder.addCase(updateUser.rejected, (state, action): void => {
      state.isLoading = false;
    });

    // [4] => Delete User.
    builder.addCase(deleteUser.pending, (state, action): void => {});
    builder.addCase(deleteUser.fulfilled, (state, action): void => {
      state.users = state.users.filter(
        (item: any) => item.id !== action.payload
      );
      state.total = state.total - 1;
      useMsgSuccess("تم الحذف  المستخدم بنجاح");
    });
    builder.addCase(deleteUser.rejected, (state, action): void => {});
  },
});

export default userSlice.reducer;
export const { nextPage, prevPage } = userSlice.actions;

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";

const Filter: React.FC<any> = ({ children, fun, action, text }) => {
    const disptach = useAppDispatch()
  return (
    <div>
      <button className=" flex items-center text-sm h-8 w-[128px] md:w-[128px] justify-between bg-ddd py-1 px-2 rounded-tr-sm rounded-tl-sm relative" onClick={() => disptach(fun())}>
        <p className=" whitespace-nowrap">{text}</p>
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
      {
        action && 
      <ul className=" absolute z-50 bg-eee w-[128px] md:w-[128px] max-h-52 overflow-y-scroll overflow-x-hidden py-1 shadow-box-2 text-sm cursor-pointer rounded-br-sm rounded-bl-sm">
        {children}
      </ul>
      }
    </div>
  );
};

export default Filter;

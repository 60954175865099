import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";
import { useAppSelector } from "../../../hooks/store/useAppSelector";
import Swal from "sweetalert2";
import Table from "../table/Table";
import Button from "../button/Button";
import { setDefault } from "../../../store/users/main/formSliceUser";
import {
  deleteUser,
  getAllUsers,
  nextPage,
  prevPage,
} from "../../../store/users/main/usersSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPlus,
  faTrashCan,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

const ViewUsers: React.FC = () => {
  const dispatch = useAppDispatch();
  const { users, curent_page, total, number_page, isLoading } = useAppSelector(
    (state) => state.users
  );

  useEffect(() => {
    if (curent_page === 1) {
      dispatch(getAllUsers(1));
    } else {
      const time = setTimeout(() => {
        dispatch(getAllUsers(curent_page));
      }, 1200);

      return () => clearTimeout(time);
    }
  }, [dispatch, curent_page]);

  const Row = users.map((item, index) => (
    <tr className=" border-b solid border-eee hover:bg-eee" key={index}>
      <td className="p-2 text-lg text-center">
        <FontAwesomeIcon icon={faUser} />
      </td>
      <td className="p-2 text-lg text-center">
        <p className=" whitespace-nowrap">{item.name}</p>
      </td>
      <td className="p-2 text-lg text-center">
        <p className=" whitespace-nowrap">{item.email}</p>
      </td>
      <td className="p-2 text-lg text-center">
        <p className=" whitespace-nowrap">{item.roles_name[0]}</p>
      </td>
      <td className="p-2 text-lg text-center">
        <div className=" flex gap-4 justify-center">
          <Link to="form-user" className=" flex justify-center items-center">
            <FontAwesomeIcon
              icon={faPenToSquare}
              className=" cursor-pointer text-green"
              onClick={() => dispatch(setDefault(item))}
            />
          </Link>
          <FontAwesomeIcon
            icon={faTrashCan}
            className=" cursor-pointer text-red"
            onClick={() =>
              Swal.fire({
                title: " هل أنت متأكد!",
                text: "في حالة الحذف لن تتمكن من العودة إلي هذا.",
                imageUrl: "/trash.svg",
                imageWidth: 100,
                showCancelButton: true,
                confirmButtonColor: "#D9003F",
                cancelButtonColor: "#4A5CB6",
                confirmButtonText: "نعم",
                cancelButtonText: "لا",
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(deleteUser(item.id));
                }
              })
            }
          />
        </div>
      </td>
    </tr>
  ));

  return (
    <>
      <div className=" h-9">
        <Button
          icon={<FontAwesomeIcon icon={faPlus} />}
          text={"مستخدم"}
          route={"form-user"}
          fun={setDefault(undefined)}
        />
      </div>
      <Table
        tHead={[
          <FontAwesomeIcon icon={faUsers} />,
          "الأسم",
          "البريد الالكتروني",
          "الصلاحية",
          "التعديل & الحذف",
        ]}
        total={total}
        curent_page={curent_page}
        number_page={number_page}
        nextPage={nextPage}
        prevPage={prevPage}
        loading={isLoading}
      >
        {Row}
      </Table>
    </>
  );
};

export default ViewUsers;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { End_LOGIN, End_LOGOUT, URL_BASEURL } from "../../api/api";
import { authState, objectAuth } from "./interfaceAuth";
import axios from "axios";
// => Notification
import {
  useMsgError,
  useMsgInfo,
  useMsgSuccess,
} from "../../hooks/toastify/notification";
// => Cookie
import Cookie from "cookie-universal";
const cookie = Cookie();

/*
  ==============================
  ====== CreateAsyncThunk ======
  ==============================
*/

// [1] => Login.
export const login = createAsyncThunk(
  "auth/login",
  async (dataUser: objectAuth, thunkAPI) => {
    try {
      const res = await axios
        .post(`${URL_BASEURL}${End_LOGIN}`, dataUser)
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [2] => Logout.
export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    await axios.post(`${URL_BASEURL}${End_LOGOUT}`, {
      token: cookie.get("access_token"),
    });
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

/*
  ===================
  ====== Slice ======
  ===================
*/

// Default State
const initialState: authState = {
  isLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // [1] => Login.
    builder.addCase(login.pending, (state, action): void => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action): void => {
      state.isLoading = false;
      if (action.payload.status) {
          cookie.set("access_token", action.payload.access_token);
          cookie.set("permission", action.payload.roles_name[0].name);
          cookie.set("name_user", action.payload.name_user);
          useMsgSuccess(action.payload.message);
      } else {
        useMsgError(action.payload.message);
      }
    });
    builder.addCase(login.rejected, (state, action): void => {
      state.isLoading = false;
    });

    // [2] => Logout.
    builder.addCase(logout.pending, (state, action): void => {});
    builder.addCase(logout.fulfilled, (state, action): void => {
      cookie.remove("access_token");
      cookie.remove("permission");
      cookie.remove("name_user");
    });
    builder.addCase(logout.rejected, (state, action): void => {});
  },
});

export default authSlice.reducer;

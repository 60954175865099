import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon, point } from "leaflet";
import L from "leaflet";
import { setShowControleOrderInMap, setShowFullMap, setShowSwapMap } from "../../../../store/main/mainSlice";
import { useAppSelector } from "../../../../hooks/store/useAppSelector";
import { useAppDispatch } from "../../../../hooks/store/useAppDispatch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping, faBoxesStacked, faCompress, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import {
  getAllDrivers,
  getAllOrdersToday,
  updateInvoice,
} from "../../../../store/order/invoiceSlice";
import {
  setDriverId,
  setOrderFromMap,
} from "../../../../store/order/formSliceOrder";
import { useMsgSuccess } from "../../../../hooks/toastify/notification";
import Cookie from "cookie-universal";

const MapGps: React.FC = () => {
  const cookie = Cookie();
  const dispatch = useAppDispatch();
  const { showControleOrderInMap, showFullMap } = useAppSelector((state) => state.mainSlice);
  const { allOrdersToday, allDrivers } = useAppSelector((state) => state.order);
  const { formOrder } = useAppSelector((state) => state.formOrder);
  // customIconDefault
  const customIconDefault = new Icon({
    iconUrl: "location-default.svg",
    iconSize: [38, 38], // size of the icon
  });

  // customIconUnaccept
  const customIconReady = new Icon({
    iconUrl: "location-yellow.svg",
    iconSize: [38, 38], // size of the icon
  });

  // customIconAccept
  const customIconAccept = new Icon({
    iconUrl: "location-green.svg",
    iconSize: [38, 38], // size of the icon
  });

  // customIconUnaccept
  const customIconUnaccept = new Icon({
    iconUrl: "location-red.svg",
    iconSize: [38, 38], // size of the icon
  });

  // customIconUnaccept
  const customIconNull = new Icon({
    iconUrl: "location-red.svg",
    className: " hidden",
    iconSize: [0, 0], // size of the icon
  });

  // create CustomIcon
  const createCustomIcon = (cluster: any) => {
    return L.divIcon({
      html: `<span class=" text-lg h-full flex justify-center items-center">${cluster.getChildCount()}</span>`,
      className: " bg-[#52b9d8] rounded-full text-white",
      iconSize: point(33, 33, true),
    });
  };

  const drivers = allDrivers.map((item: any) => (
    <p key={item.id}>
      <label className=" flex gap-1 items-center cursor-pointer">
        <input
          type="radio"
          name="driverOrder"
          checked={item.id === formOrder.driver_id}
          onChange={() => dispatch(setDriverId(item.id))}
        />
        <p className=" text-base">{item.name}</p>
      </label>
    </p>
  ));

  useEffect(() => {
    dispatch(getAllOrdersToday());
    dispatch(getAllDrivers());
  }, [dispatch]);

  return (
    <div
    className={` ${
      showFullMap
        ? " absolute top-0 left-0 h-screen w-screen transition-all duration-150 z-[10000000]"
        : "grid grid-cols-12 gap-4 p-1 rounded-md shadow-box-2 bg-eee relative"
    }`}
    >
      <div className=" absolute top-[77px] left-[14px] bg-white border-solid border-2 border-[#c2bfba] border-t-0 px-2 py-1 rounded-b-md z-[10000006] hover:bg-eee cursor-pointer" onClick={() => dispatch(setShowFullMap())}>
        <FontAwesomeIcon icon={faCompress} />
      </div>
      {
        cookie.get("permission") === "Owner" ?
      <div className=" absolute right-0 top-0 py-1 px-2 z-[1000] bg-blue text-white flex gap-4 text-sm">
        <div className=" flex gap-2 items-center cursor-pointer" onClick={() => dispatch(setShowSwapMap(true))}>
          <FontAwesomeIcon icon={faBoxesStacked} />
          <p>الطلبات</p>
        </div>
        <div className=" flex gap-2 items-center cursor-pointer" onClick={() => dispatch(setShowSwapMap(false))}>
          <FontAwesomeIcon icon={faBagShopping} />
          <p>العملاء</p>
        </div>
      </div>
      : ""
      }
      <div className={`${showFullMap ? " h-screen" : " col-span-12 h-96"}`}>
      <MapContainer
        center={[24.719161549111778, 46.68777056344977]}
        zoom={9}
        className=" h-full"
      >
        {showControleOrderInMap && (
          <div
            className={` ${
              showControleOrderInMap ? "opacity-1" : "opacity-0"
            } fixed top-0 left-0 w-screen h-screen z-[900000000000] bg-[#00000033] flex justify-center items-center transition-all duration-150 origin-center`}
          >
            <div className=" w-[320px] h-52 bg-eee p-2 rounded-lg overflow-hidden flex flex-col gap-2 shadow-box">
              <div className=" flex justify-center">
                <p className=" text-lg">أختر السائق</p>
              </div>
              <div className=" flex-1 overflow-x-hidden overflow-y-scroll flex flex-wrap gap-x-3 items-center">
                {drivers}
              </div>
              <div className=" flex justify-center gap-3">
                <button
                  className=" bg-green text-lg text-white py-1 px-2 rounded-sm w-16"
                  onClick={() => {
                    dispatch(updateInvoice(formOrder)).then(() => {
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      useMsgSuccess("تم تحديد السائق بنجاح");
                      dispatch(setShowControleOrderInMap());
                    });
                  }}
                >
                  تأكيد
                </button>
                <button
                  className=" bg-red text-lg text-white py-1 px-2 rounded-sm w-16"
                  onClick={() => {
                    dispatch(setShowControleOrderInMap());
                  }}
                >
                  الغاء
                </button>
              </div>
            </div>
          </div>
        )}
        <TileLayer
          {...{
            attribution:
              '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            className: " h-full"
          }}
        />
        <MarkerClusterGroup
          chunkedLoading
          iconCreateFunction={createCustomIcon}
        >
          {allOrdersToday.map((item: any) => (
            <Marker
              {...{
                position: [item.client_id.latitude, item.client_id.longitude],
                icon:
                  item.status === 2
                    ? customIconDefault
                    : item.status === 3
                    ? customIconReady
                    : item.status === 4 || item.status === 5
                    ? customIconAccept
                    : item.status === 6
                    ? customIconUnaccept
                    : customIconNull,
              }}
              key={item.id}
            >
              <Popup>
                <div className=" flex gap-2 text-right text-[12px]">
                  <ul>
                    <li className=" p-[1px]">
                      <span>الاسم</span>
                    </li>
                    <li className=" p-[1px]">
                      <span>اسم السائق</span>
                    </li>
                    <li className=" p-[1px]">
                      <span>رقم الفاتورة</span>
                    </li>
                    <li className=" p-[1px]">
                      <span>الحالة</span>
                    </li>
                  </ul>
                  <ul>
                    <li className=" p-[1px]">
                      <span>{item.client_id.name}</span>
                    </li>
                    <li className=" p-[1px]">
                      <span>
                        {item.driver_id.id === 1
                          ? "لم يتم تحديد سائق"
                          : item.driver_id.name}
                      </span>
                    </li>
                    <li className=" p-[1px]">
                      <span>{item.invoice_number}</span>
                    </li>
                    <li className=" p-[1px]">
                      <div className=" flex items-start gap-2">
                        <span>
                          {item.status === 0
                            ? "تم رفع الطلب"
                            : item.status === 1
                            ? "تم اعتماد الطلب"
                            : item.status === 2
                            ? "يتم تحضير الطلب"
                            : item.status === 3
                            ? "الطلب مع السائق"
                            : item.status === 4
                            ? "تم تسليم الطلب"
                            : item.status === 5
                            ? "تم استلام المبلغ"
                            : item.status === 6
                            ? " كنسل الطلب"
                            : "تم تقفيل الطلب"}
                        </span>
                        {(item.status === 2 || item.status === 3) && (
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className=" cursor-pointer text-[#00BE10]"
                            onClick={() => {
                              dispatch(setOrderFromMap(item));
                              dispatch(setShowControleOrderInMap());
                            }}
                          />
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
      </div>
    </div>
  );
};

export default MapGps;

import React from "react";
import { useAppDispatch } from "../../../hooks/store/useAppDispatch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faCircleInfo,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";

// prop Compoent
interface prop {
  type?: string;
  value?: string;
  text?: string;
  data?: any[];
  error?: boolean;
  fun?: any;
  nestFun?: any;
  action?: boolean;
  children?: any;
}

const DropMenu: React.FC<prop> = ({
  type,
  value,
  text,
  data,
  error,
  fun,
  nestFun,
  action,
  children,
}) => {
  const dispatch = useAppDispatch();

  return (
    <div className="col-span-12 md:col-span-6 flex flex-col gap-y-1">
      <p className="text-lg">{text}</p>
      <div className="col-span-12 md:col-span-6 flex flex-col">
        <div
          className={
            error
              ? `w-full h-11 p-2 rounded-sm bg-white border-2 border-red cursor-pointer flex justify-between items-center`
              : `w-full h-11 p-2 rounded-sm bg-white border-2 cursor-pointer flex justify-between items-center hover:bg-[#eeeeeed0] transition-all duration-150`
          }
          onClick={() => dispatch(fun())}
        >
          {action ? (
            <>
              <p>إخفاء القائمة</p>
              <FontAwesomeIcon icon={faChevronUp} />
            </>
          ) : (
            <>
              <p>إظهار القائمة</p>
              <FontAwesomeIcon icon={faChevronDown} />
            </>
          )}
        </div>
        {action && (
          <div className=" py-2 px-1 text-sm bg-eee shadow-box-2 mt-[-1px] border-2 border-t-0 max-h-32 overflow-y-scroll">
            {children}
          </div>
        )}
        {error && (
          <div className=" flex items-center gap-2 text-red text-sm">
            <FontAwesomeIcon icon={faCircleInfo} />
            <p className=" text-red text-sm">{`من فضلك قم بإختيار ${text}`}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropMenu;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { End_CLIENTS, URL_BASEURL } from "../../../api/api";
import { filterClientByWork } from "../work/workSlice";
import { filterClientByArea } from "../area/areaSlice";
import axios from "axios";
// => Notification
import {
  useMsgError,
  useMsgSuccess,
} from "../../../hooks/toastify/notification";
// => Cookie
import Cookie from "cookie-universal";
const cookie = Cookie();

/*
  ==============================
  ====== CreateAsyncThunk ======
  ==============================
*/

// [2] => Get All Client.
export const getAllClients = createAsyncThunk(
  "client/getAll",
  async (numPage: number, thunkAPI) => {
    try {
      const res = await axios
        .get(`${URL_BASEURL}${End_CLIENTS}?page=${numPage}`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [3] => Create Client.
export const createClient = createAsyncThunk(
  "client/create",
  async (dataClient: any, thunkAPI) => {
    const {
      name,
      phone,
      phone2,
      location_description,
      number_of_units,
      client_type_id,
      accountant_nationality_id,
      opening_time,
      closing_time,
      latitude,
      longitude,
      is_24_hours,
      call_time,
      delivery_time,
      shop_photo,
      signature_photo,
      street_type,
      area_id,
    } = dataClient;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("phone2", phone2);
    formData.append("location_description", location_description);
    formData.append("number_of_units", number_of_units);
    formData.append("client_type_id", client_type_id);
    formData.append("accountant_nationality_id", accountant_nationality_id);
    formData.append("opening_time", opening_time);
    formData.append("closing_time", closing_time);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("is_24_hours", is_24_hours);
    delivery_time.forEach((value: any, index: number) => {
      formData.append(`delivery_time[${index}]`, value);
    });
    call_time.forEach((value: any, index: number) => {
      formData.append(`call_time[${index}]`, value);
    });
    formData.append("shop_photo", shop_photo);
    formData.append("signature_photo", signature_photo);
    formData.append("street_type", street_type);
    formData.append("area_id", area_id);
    try {
      const res = await axios
        .post(`${URL_BASEURL}${End_CLIENTS}`, formData, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [4] => Update Client.
export const updateClient = createAsyncThunk(
  "client/update",
  async (dataEdit: any, thunkAPI) => {
    const {
      name,
      phone,
      phone2,
      location_description,
      number_of_units,
      client_type_id,
      accountant_nationality_id,
      opening_time,
      closing_time,
      latitude,
      longitude,
      is_24_hours,
      call_time,
      delivery_time,
      shop_photo,
      signature_photo,
      street_type,
      area_id,
    } = dataEdit;
    const formDataEdit = new FormData();
    formDataEdit.append("name", name);
    formDataEdit.append("phone", phone);
    formDataEdit.append("phone2", phone2);
    formDataEdit.append("location_description", location_description);
    formDataEdit.append("number_of_units", number_of_units);
    formDataEdit.append("client_type_id", client_type_id);
    formDataEdit.append("accountant_nationality_id", accountant_nationality_id);
    formDataEdit.append("opening_time", opening_time);
    formDataEdit.append("closing_time", closing_time);
    formDataEdit.append("latitude", latitude);
    formDataEdit.append("longitude", longitude);
    formDataEdit.append("is_24_hours", is_24_hours);
    formDataEdit.append("area_id", area_id);
    delivery_time.forEach((value: any, index: number) => {
      formDataEdit.append(`delivery_time[${index}]`, value);
    });
    call_time.forEach((value: any, index: number) => {
      formDataEdit.append(`call_time[${index}]`, value);
    });
    formDataEdit.append("shop_photo", shop_photo);
    formDataEdit.append("signature_photo", signature_photo);
    formDataEdit.append("street_type", street_type);

    try {
      const res = await axios
        .post(`${URL_BASEURL}${End_CLIENTS}/${dataEdit.id}`, formDataEdit, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [5] => Delete Client.
export const deleteClient = createAsyncThunk(
  "client/delete",
  async (clientId: number, thunkAPI) => {
    try {
      await axios.delete(`${URL_BASEURL}${End_CLIENTS}/${clientId}`, {
        headers: {
          Authorization: "bearer " + cookie.get("access_token"),
        },
      });

      return clientId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//
export const getAllLocationClients = createAsyncThunk(
  "client/location",
  async (_, thunkAPI) => {
    try {
      const res = await axios
        .get(`http://test.ramz-aluqud.com/api/clients/loction`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);
      const data = res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/*
  ===================
  ====== Slice ======
  ===================
*/

type initState = {
  isLoading: boolean;
  clients: any[];
  locationsClient: any[];
  curent_page: number;
  total: number;
  number_page: number;
  searchValue: string;
};

const initialState: initState = {
  isLoading: false,
  clients: [],
  locationsClient: [],
  total: 0,
  curent_page: 1,
  number_page: 1,
  searchValue: "",
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    // Set Data
    nextPage: (state): void => {
      if (state.curent_page < state.number_page) {
        state.curent_page = state.curent_page + 1;
      }
    },
    prevPage: (state): void => {
      if (state.curent_page > 1) {
        state.curent_page = state.curent_page - 1;
      }
    },

    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    selectClientSearch: (state, action) => {
      state.clients = [action.payload];
      state.searchValue = action.payload.name;
    },
  },
  extraReducers: (builder) => {
    // [2] => Get All Client.
    builder.addCase(getAllLocationClients.pending, (state, action) => {});
    builder.addCase(getAllLocationClients.fulfilled, (state, action) => {
      if (action.payload.successful) {
          state.locationsClient = action.payload.data;
      }
    });
    builder.addCase(getAllLocationClients.rejected, (state, action) => {});

    // [2] => Get All Client.
    builder.addCase(getAllClients.pending, (state, action) => {});
    builder.addCase(getAllClients.fulfilled, (state, action) => {
      if (action.payload.successful) {
        state.clients = action.payload.data;
        state.total = action.payload.pagination.total;
        state.number_page = Math.ceil(
          action.payload.pagination.total / action.payload.pagination.per_page
        );
      }
    });
    builder.addCase(getAllClients.rejected, (state, action) => {});

    // [3] => Create Client.
    builder.addCase(createClient.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createClient.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.successful) {
        state.clients.push(action.payload.data);
        state.total = state.total + 1;
        useMsgSuccess("تم إضافة عميل بنجاح");
      } else {
        if (action.payload.message.phone) {
          useMsgError("رقم الجوال مستخدم من قبل");
        } else {
          useMsgError("تأكد من صحة البيانات المرفوعة");
        }
      }
    });
    builder.addCase(createClient.rejected, (state, action) => {
      state.isLoading = false;
    });

    // [4] => Update Client.
    builder.addCase(updateClient.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateClient.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.successful) {
        state.clients.push(action.payload.data);
        state.total = state.total + 1;
        useMsgSuccess("تم تعديل عميل بنجاح");
      } else {
        if (action.payload.message.phone) {
          useMsgError("رقم الجوال مستخدم من قبل");
        } else {
          useMsgError("تأكد من صحة البيانات المرفوعة");
        }
      }
    });
    builder.addCase(updateClient.rejected, (state, action) => {
      state.isLoading = false;
    });

    // [5] => Delete Client.
    builder.addCase(deleteClient.pending, (state, action) => {});
    builder.addCase(deleteClient.fulfilled, (state, action) => {
      state.clients = state.clients.filter(
        (item) => item.id !== action.payload
      );
      state.total = state.total - 1;
      useMsgSuccess("تم الحذف العميل بنجاح");
    });
    builder.addCase(deleteClient.rejected, (state, action) => {});

    // [6] => Filter Work Client.
    builder.addCase(filterClientByWork.fulfilled, (state, action) => {
      if (action.payload.successful) {
        state.clients = action.payload.data;
      } else {
        state.clients = [];
      }
    });

    // [7] => Filter Area Client.
    builder.addCase(filterClientByArea.fulfilled, (state, action) => {
      if (action.payload.successful) {
        state.clients = action.payload.data;
      } else {
        state.clients = [];
      }
    });
  },
});

export default clientSlice.reducer;
export const { nextPage, prevPage, selectClientSearch, setSearchValue } =
  clientSlice.actions;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
// => Notification
import { useMsgSuccess } from "../../hooks/toastify/notification";
// => Cookie
import Cookie from "cookie-universal";
const cookie = Cookie();

/*
  ==============================
  ====== CreateAsyncThunk ======
  ==============================
*/

//
export const searchOrderByNumberInvoice = createAsyncThunk(
  "order/searchOrderByNumberInvoice",
  async (num: any, thunkAPI) => {
    try {
      const res = await axios
        .get(`http://test.ramz-aluqud.com/api/orders/search/${num}`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [2] => Get All Invoice.
export const getAllInvoice = createAsyncThunk(
  "order/getAll",
  async (numPage: number, thunkAPI) => {
    try {
      const res = await axios
        .get(`http://test.ramz-aluqud.com/api/orders?page=${numPage}`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//
export const getAllOrdersToday = createAsyncThunk(
  "order/getAllOrdersToday",
  async (_, thunkAPI) => {
    try {
      const res = await axios
        .get(`http://test.ramz-aluqud.com/api/getAllOrdersToday`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [3] => create Invoice.
export const createInvoice = createAsyncThunk(
  "order/create",
  async (orderData: any, thunkApi) => {
    try {
      const res = await axios
        .post(`http://test.ramz-aluqud.com/api/orders`, orderData, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

// [4] => Update Invoice.
export const updateInvoice = createAsyncThunk(
  "order/update",
  async (invoiceData: any, thunkAPI) => {
    try {
      const res = await axios
        .post(
          `http://test.ramz-aluqud.com/api/orders/${invoiceData.id}`,
          invoiceData,
          {
            headers: {
              Authorization: "bearer " + cookie.get("access_token"),
            },
          }
        )
        .then((response) => response);

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [5] => Update Invoice.
export const deleteInvoice = createAsyncThunk(
  "order/delete",
  async (orderId: number, thunkAPI) => {
    try {
      await axios.delete(`http://test.ramz-aluqud.com/api/orders/${orderId}`, {
        headers: {
          Authorization: "bearer " + cookie.get("access_token"),
        },
      });

      return orderId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// [6] => Get All Drivers.
export const getAllDrivers = createAsyncThunk(
  "order/getAllDrivers",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get(
        `http://test.ramz-aluqud.com/api/getAllDriver`,
        {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        }
      );

      const data = await res.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//
export const filterOrderByStatus = createAsyncThunk(
  "order/filterOrderByStatus",
  async (dataOrder: any, thunkAPI) => {
    try {
      const res = await axios
        .get(`http://test.ramz-aluqud.com/api/orders/filter/${dataOrder.status}?page=${dataOrder.numPage}`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

        const data = await res.data;
        return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/*
  ===================
  ====== Slice ======
  ===================
*/

interface initSTate {
  invoices: any[];
  allDrivers: any[];
  allOrdersToday: any[];
  searchOrderResult: any[];
  searchValue: number | string;
  isLoading: boolean;
  total: number;
  curent_page: number;
  number_page: number;
  typeInvoice: string;
  statusInvoice: number;
}

const initialState: initSTate = {
  invoices: [],
  allDrivers: [],
  allOrdersToday: [],
  searchOrderResult: [],
  searchValue: "",
  isLoading: false,
  total: 0,
  curent_page: 1,
  number_page: 0,
  typeInvoice: "",
  statusInvoice: -1,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    // Set Date
    nextPage: (state): void => {
      if (state.curent_page < state.number_page) {
        state.curent_page = state.curent_page + 1;
      }
    },
    prevPage: (state): void => {
      if (state.curent_page > 1) {
        state.curent_page = state.curent_page - 1;
      }
    },

    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    selectOrderSearch: (state, action) => {
      state.allOrdersToday = [action.payload];
      state.searchValue = action.payload.invoice_number;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchOrderByNumberInvoice.pending, (state, action) => {});
    builder.addCase(searchOrderByNumberInvoice.fulfilled, (state, action) => {
      if (action.payload.successful) {
        state.invoices = action.payload.data;
      } else {
        state.invoices = [];
      }
    });
    builder.addCase(searchOrderByNumberInvoice.rejected, (state, action) => {});

    //
    builder.addCase(filterOrderByStatus.pending, (state, action) => {});
    builder.addCase(filterOrderByStatus.fulfilled, (state, action) => {
      state.typeInvoice = "filter";
      if (action.payload.successful) {
        state.statusInvoice = action.payload.data[0]?.status;
        state.invoices = action.payload.data;
        state.total = action.payload.pagination.total;
        state.curent_page = action.payload.pagination.current_page;
        state.number_page = Math.ceil(
          action.payload.pagination.total / action.payload.pagination.per_page
        );
      } else {
        state.invoices = [];
        state.total = 0;
        state.curent_page = 1;
        state.number_page = 1;
      }
    });
    builder.addCase(filterOrderByStatus.rejected, (state, action) => {});

    // [2] => Get All Invoice.
    builder.addCase(getAllInvoice.pending, (state, action) => {});
    builder.addCase(getAllInvoice.fulfilled, (state, action) => {
      state.typeInvoice = "";
      if (action.payload.successful) {
        state.invoices = action.payload.data;
        state.total = action.payload.pagination.total;
        state.curent_page = action.payload.pagination.current_page;
        state.number_page = Math.ceil(
          action.payload.pagination.total / action.payload.pagination.per_page
        );
      }
    });
    builder.addCase(getAllInvoice.rejected, (state, action) => {});

    builder.addCase(getAllOrdersToday.pending, (state, action) => {});
    builder.addCase(getAllOrdersToday.fulfilled, (state, action) => {
      if (action.payload.successful) {
        state.allOrdersToday = action.payload.data;
      }
    });
    builder.addCase(getAllOrdersToday.rejected, (state, action) => {});

    // [3] => create Invoice.
    builder.addCase(createInvoice.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.successful) {
        state.invoices.push(action.payload.data);
        state.total = state.total + 1;
        useMsgSuccess(action.payload.message);
      }
    });
    builder.addCase(createInvoice.rejected, (state, action) => {
      state.isLoading = false;
    });

    // [4] => Update Invoice.
    builder.addCase(updateInvoice.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.successful) {
        state.invoices = state.invoices.filter(
          (item) => item.id !== action.payload.data.id
        );
        state.allOrdersToday = state.allOrdersToday.filter(
          (item) => item.id !== action.payload.data.id
        );
        state.allOrdersToday.push(action.payload.data);
        state.invoices.push(action.payload.data);
      }
    });
    builder.addCase(updateInvoice.rejected, (state, action) => {
      state.isLoading = false;
    });

    // [5] => Update Invoice.
    builder.addCase(deleteInvoice.pending, (state, action) => {});
    builder.addCase(deleteInvoice.fulfilled, (state, action) => {
      state.invoices = state.invoices.filter(
        (item) => item.id !== action.payload
      );
      state.total = state.total - 1;
      useMsgSuccess("تم الحذف بنجاح");
    });
    builder.addCase(deleteInvoice.rejected, (state, action) => {});

    // [6] => Get All Drivers.
    builder.addCase(getAllDrivers.pending, (state, action) => {});
    builder.addCase(getAllDrivers.fulfilled, (state, action) => {
      if (action.payload.successful) {
        state.allDrivers = action.payload.data;
      }
    });
    builder.addCase(getAllDrivers.rejected, (state, action) => {});
  },
});

export default orderSlice.reducer;
export const { nextPage, prevPage, setSearchValue, selectOrderSearch } =
  orderSlice.actions;

import React from "react";
import Img from "../dashboard/img/Img";

// => Props Component
interface prop {
  version: string;
}

const InformationForCompany: React.FC<prop> = ({ version }) => {
  return (
    <>
      <div className=" absolute top-2 left-2 flex gap-x-2 text-md">
        <p>V ~</p>
        <p>{version}</p>
      </div>
      <Img src="/logo.png" alt="شعار رمز العقود" css="w-20 md:w-28 lg:w-36" />
      <span className="w-[1px] h-32 bg-ddd mx-6"></span>
      <Img src="/itqan-logo.png" alt="شعار اتقان" css="w-20 md:w-28 lg:w-36" />
    </>
  );
};

export default InformationForCompany;
